import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';

interface Script {
  name: string;
  description?: string;
  price: number;
  link: string;
  extras?: string[];
  highlight?: boolean;
}

const PAID_SCRIPTS: Script[] = [
  {
    name: "Single Account PMax Script",
    price: 199,
    link: "https://mikerhodes.circle.so/checkout/latest-script",
    description: "Used by 2000+ agencies",
    extras: [
      "40 min video walkthrough",
      "Detailed documentation",
      "Free support & updates"
    ]
  },
  {
    name: "MCC PMax Script",
    price: 399,
    link: "https://mikerhodes.circle.so/checkout/latest-mcc-script",
    description: "Use on unlimited accounts",
    extras: [
      "Setup walkthrough video",
      "All documentation included",
      "Free support & updates"
    ]
  },
  {
    name: "Negative Keyword Script",
    price: 109,
    link: "https://mikerhodes.circle.so/checkout/negative-keyword-script",
    description: "Includes MCC version",
    extras: [
      "Works with Search campaigns",
      "Use AI to build lists, fast",
      "Free support & updates"
    ]
  },
  {
    name: "Scripts & Sheets Mastery Course",
    price: 299,
    link: "https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop",
    description: "Learn how to create your own",
    extras: [
      "Comprehensive training",
      "Example scripts included",
      "Lifetime access"
    ]
  },
  {
    name: "MASSIVE VALUE PACKAGE 🤪",
    price: 499,
    link: "https://mikerhodes.circle.so/checkout/massive-bundle",
    description: "Get everything above (& bonuses) for a crazy discount",
    extras: [
      "All scripts included",
      "Full course access",
      "Priority support",
      "Save over $400"
    ],
    highlight: true
  }
];

const ScriptCard: React.FC<{ script: Script }> = ({ script }) => (
  <Card className={cn(
    "w-full transition-all duration-200 hover:shadow-lg",
    script.highlight && "border-2 border-orange-500 dark:border-orange-400"
  )}>
    <CardHeader>
      <CardTitle className="flex justify-between items-center">
        <span className={cn(
          "text-xl",
          script.highlight && "text-orange-600 dark:text-orange-400"
        )}>
          {script.name}
        </span>
        <span className={cn(
          "text-2xl font-bold",
          script.highlight && "text-orange-600 dark:text-orange-400"
        )}>
          ${script.price}
        </span>
      </CardTitle>
    </CardHeader>
    <CardContent>
      {script.description && (
        <p className="mb-4 text-muted-foreground">{script.description}</p>
      )}
      {script.extras && (
        <ul className="list-none space-y-2 mb-6">
          {script.extras.map((extra, i) => (
            <li key={i} className="flex items-center">
              <span className="text-green-500 mr-2">✓</span>
              {extra}
            </li>
          ))}
        </ul>
      )}
      <a 
        href={script.link}
        className={cn(
          "inline-block w-full text-center px-4 py-3 rounded transition-colors",
          script.highlight 
            ? "bg-orange-500 hover:bg-orange-600 text-white"
            : "bg-blue-600 hover:bg-blue-700 text-white"
        )}
      >
        Get Access
      </a>
    </CardContent>
  </Card>
);

export default function ScriptsPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Scripts & Tools</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
        {PAID_SCRIPTS.map((script, i) => (
          <ScriptCard key={i} script={script} />
        ))}
      </div>

      <section className="mt-12 bg-orange-600 text-white p-8 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Simple 'Pay Once' Pricing</h2>
        <ul className="space-y-3">
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            No subscriptions or recurring fees
          </li>
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            Unlimited, free updates for life
          </li>
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            Free support included (paid scripts)
          </li>
          <li className="flex items-center">
            <span className="mr-2">✓</span>
            All prices in USD
          </li>
        </ul>
      </section>
    </div>
  );
}