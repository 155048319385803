// src/contexts/campaign-data.tsx
import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { DATA_SOURCES, STORAGE_KEYS } from '@/lib/constants';
import { initializeWithSampleData } from '@/lib/sample-data-utils';
import { useUser } from '@clerk/clerk-react';
import { FetchStatusType, StorageData, UserMetadata } from '@/types/metrics';
import { db } from '@/services/db';

export type LoadingStatus = 'idle' | 'initial' | 'refresh' | 'switching';

interface CampaignDataContextType {
  data: StorageData | null;
  loadingStatus: LoadingStatus;
  error: string | null;
  lastUpdated: Date | null;
  useSampleData: boolean;
  fetchStatus: Record<string, FetchStatusType>;
  refreshData: () => Promise<void>;
  clearData: () => Promise<void>;
  saveWebAppUrl: (url: string) => Promise<void>;
  getWebAppUrl: () => string | null;
  cogsAmount: number;
  currentGameLevel: number;
  updateCogsAmount: (amount: number) => Promise<void>;
  updateCurrentGameLevel: (level: number) => Promise<void>;
  switchToSampleData: (useSample: boolean) => Promise<void>;
}

const CampaignDataContext = createContext<CampaignDataContextType | undefined>(undefined);

const fetchWithRetry = async (url: string, retries = 2): Promise<any> => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      console.error(`Attempt ${i + 1} failed for ${url}`);
      if (i === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
    }
  }
  throw new Error(`Failed to fetch data from ${url} after ${retries} attempts`);
};


export function CampaignDataProvider({ children }: { children: React.ReactNode }) {
  const { user, isSignedIn } = useUser();
  const [data, setData] = useState<StorageData | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('initial');
  const [error, setError] = useState<string | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [useSampleData, setUseSampleData] = useState(true);
  const [cogsAmount, setCogsAmount] = useState<number>(50);
  const [currentGameLevel, setCurrentGameLevel] = useState(0);
  const [fetchStatus, setFetchStatus] = useState<Record<string, FetchStatusType>>({});
  const refreshInterval = useRef<NodeJS.Timeout>();
  const initialized = useRef(false);

  const getWebAppUrl = (): string | null => {
    return user?.unsafeMetadata?.webAppUrl as string | null;
  };

  const updateUserMetadata = async (metadataUpdates: Partial<UserMetadata>) => {
    if (!user) return;

    const updatedMetadata = {
      ...user.unsafeMetadata,
      ...metadataUpdates,
      lastUpdated: new Date().toISOString(),
    };

    await user.update({ unsafeMetadata: updatedMetadata });

    if ('cogsAmount' in metadataUpdates) setCogsAmount(metadataUpdates.cogsAmount!);
    if ('currentGameLevel' in metadataUpdates) setCurrentGameLevel(metadataUpdates.currentGameLevel!);
    if ('webAppUrl' in metadataUpdates) setUseSampleData(false);
  };

  const initializeSampleData = async (): Promise<StorageData> => {
    const existingSampleData = await db.getSampleData();
    if (existingSampleData) {
      return existingSampleData;
    }

    const sampleData = await initializeWithSampleData(true);
    await db.saveData(sampleData, true);
    return sampleData;
  };

  const fetchFreshUserData = async (): Promise<StorageData> => {
    const webAppUrl = getWebAppUrl();
    if (!webAppUrl) {
      throw new Error('No Web App URL configured');
    }

    const newData: StorageData = {
      timestamp: new Date().toISOString(),
      daily: [],
      hourly: [],
      hourly_yest: [],
      thirty_days: [],
      p_thirty_days: [],
      seven_days: [],
      p_seven_days: [],
      settings: [],
      products: [],
      pmax_perf: [],
      search_terms: []
    };

    for (const tab of Object.keys(DATA_SOURCES)) {
      try {
        const tabUrl = `${webAppUrl}?tab=${tab}`;
        const tabData = await fetchWithRetry(tabUrl);
        if (Array.isArray(tabData)) {
          newData[tab as keyof StorageData] = tabData as any;
        }
      } catch (tabError) {
        console.error(`Error fetching ${tab} data:`, tabError);
      }
    }

    await db.saveData(newData, false);
    return newData;
  };

  const refreshData = async (showLoadingUI = true) => {
    setLoadingStatus(showLoadingUI ? 'initial' : 'refresh');
    setError(null);

    try {
      if (useSampleData) {
        const sampleData = await initializeSampleData();
        setData(sampleData);
        setLastUpdated(new Date());
        return;
      }

      if (!isSignedIn) {
        throw new Error('User must be signed in to refresh data');
      }

      const freshData = await fetchFreshUserData();
      setData(freshData);
      setLastUpdated(new Date());
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to refresh data');
      console.error('Error refreshing data:', err);
      
      // If user data refresh fails, fallback to existing data
      const existingData = await db.getUserData();
      if (!existingData) {
        // If no existing data, fallback to sample data
        console.warn('No existing data, falling back to sample data');
        const sampleData = await initializeSampleData();
        setData(sampleData);
        setUseSampleData(true);
      }
    } finally {
      setLoadingStatus('idle');
    }
  };

  const switchToSampleData = async (useSample: boolean) => {
    setLoadingStatus('switching');
    setError(null);
    
    try {
      if (useSample) {
        const sampleData = await initializeSampleData();
        setData(sampleData);
        setLastUpdated(new Date());
        setUseSampleData(true);
      } else {
        try {
          const userData = await db.getUserData();
          if (userData && !db.isDataStale(userData)) {
            setData(userData);
            setLastUpdated(new Date(userData.lastUpdated));
          } else {
            await refreshData(true);
          }
          setUseSampleData(false);
        } catch (err) {
          console.error('Failed to switch to user data:', err);
          // Fallback to sample data if switching to user data fails
          const sampleData = await initializeSampleData();
          setData(sampleData);
          setUseSampleData(true);
          throw new Error('Failed to load user data, falling back to sample data');
        }
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to switch data source');
    } finally {
      setLoadingStatus('idle');
    }
  };

  // Initialize data on first load
  useEffect(() => {
    const initialize = async () => {
      if (initialized.current) return;
      initialized.current = true;

      setLoadingStatus('initial');
      try {
        if (!isSignedIn) {
          const sampleData = await initializeSampleData();
          setData(sampleData);
          setLastUpdated(new Date());
          setUseSampleData(true);
        } else {
          const webAppUrl = getWebAppUrl();
          if (!webAppUrl) {
            const sampleData = await initializeSampleData();
            setData(sampleData);
            setLastUpdated(new Date());
            setUseSampleData(true);
          } else {
            try {
              const userData = await db.getUserData();
              if (userData && !db.isDataStale(userData)) {
                setData(userData);
                setLastUpdated(new Date(userData.lastUpdated));
                setUseSampleData(false);
              } else {
                await refreshData(true);
                setUseSampleData(false);
              }
            } catch (err) {
              console.error('Failed to initialize with user data:', err);
              const sampleData = await initializeSampleData();
              setData(sampleData);
              setUseSampleData(true);
            }
          }
        }
      } catch (err) {
        console.error('Error initializing data:', err);
        setError(err instanceof Error ? err.message : 'Failed to initialize data');
      } finally {
        setLoadingStatus('idle');
      }
    };

    initialize();
  }, [isSignedIn]);

  useEffect(() => {
    if (isSignedIn && !useSampleData) {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }

      refreshInterval.current = setInterval(() => {
        refreshData(false);
      }, 60 * 60 * 1000);
    }

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, [isSignedIn, useSampleData]);

  useEffect(() => {
    if (user?.unsafeMetadata) {
      const metadata = user.unsafeMetadata as UserMetadata;
      if (metadata.cogsAmount) setCogsAmount(metadata.cogsAmount);
      if (metadata.currentGameLevel !== undefined) {
        setCurrentGameLevel(metadata.currentGameLevel);
      }
    }
  }, [user?.unsafeMetadata]);

  const saveWebAppUrl = async (url: string) => {
    if (!user) return;
    if (!url.includes('macro')) {
      throw new Error('Invalid Web App URL: Must contain "macro"');
    }

    await updateUserMetadata({ webAppUrl: url });
    await refreshData(true);
  };
  

  const updateCogsAmount = async (amount: number) => {
    if (!user) return;
    await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        cogsAmount: amount,
        lastUpdated: new Date().toISOString()
      }
    });
    setCogsAmount(amount);
  };

  const updateCurrentGameLevel = async (level: number) => {
    setCurrentGameLevel(level);
    if (user) {
      await user.update({
        unsafeMetadata: {
          ...user.unsafeMetadata,
          currentGameLevel: level,
          lastUpdated: new Date().toISOString()
        }
      });
    }
  };

  

  return (
    <CampaignDataContext.Provider
      value={{
        data,
        loadingStatus,
        error,
        lastUpdated,
        useSampleData,
        fetchStatus,
        refreshData: () => refreshData(true),
        clearData: async () => {
          await db.clearAllData();
          setData(null);
          setLastUpdated(null);
          await updateUserMetadata({ currentGameLevel: 0 });
        },
        saveWebAppUrl,
        getWebAppUrl,
        cogsAmount,
        currentGameLevel,
        updateCogsAmount: (amount: number) => updateUserMetadata({ cogsAmount: amount }),
        updateCurrentGameLevel: (level: number) => updateUserMetadata({ currentGameLevel: level }),
        switchToSampleData,
      }}
    >
      {children}
    </CampaignDataContext.Provider>
  );
}

export function useCampaignData() {
  const context = useContext(CampaignDataContext);
  if (!context) {
    throw new Error('useCampaignData must be used within a CampaignDataProvider');
  }
  return context;
}