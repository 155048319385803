// src/components/course-intro.tsx
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, Brain, Code, Clock } from "lucide-react";

interface CourseIntroProps {
  onStart: () => void;
}

const CourseIntro: React.FC<CourseIntroProps> = ({ onStart }) => {
  const features = [
    {
      icon: <Brain className="w-6 h-6 text-blue-500" />,
      title: "No Experience Required",
      description: "Start from absolute zero - I'll guide you step by step"
    },
    {
      icon: <Code className="w-6 h-6 text-green-500" />,
      title: "Real Working Code",
      description: "Copy, paste & run actual scripts that work in your account"
    },
    {
      icon: <Clock className="w-6 h-6 text-purple-500" />,
      title: "Quick to Complete",
      description: "10 super short levels. Takes just 1-3 minutes each"
    }
  ];

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-4xl font-bold">
            Google Ads Scripts Made Simple
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-8">
            <div className="prose prose-lg text-muted-foreground">
              <p>
                Scared of scripts? Don't be. This quick tutorial will show you 
                just how easy they can be.
              </p>
              <p>
                We'll start super simple (literally 3 lines of code) and build up 
                to creating automated reports and alerts.
              </p>
            </div>

            <div className="grid gap-6 md:grid-cols-3">
              {features.map((feature, index) => (
                <Card key={index} className="bg-muted/50">
                  <CardContent className="pt-6">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        {feature.icon}
                        <h3 className="font-semibold">{feature.title}</h3>
                      </div>
                      <p className="text-sm text-muted-foreground">
                        {feature.description}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>

            <div className="flex justify-center">
              <Button
                onClick={onStart}
                size="lg"
                className="min-w-[200px] bg-orange-600 hover:bg-orange-700"
              >
                Start Learning <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CourseIntro;