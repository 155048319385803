// src/pages/trends.tsx
'use client'

import React, { useState, useMemo, useEffect } from 'react'
import { Line, LineChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Bar, BarChart, CartesianGrid } from 'recharts'
import { LineChart as LineChartIcon, BarChart as BarChartIcon } from 'lucide-react'
import { AlertCircle } from 'lucide-react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useCampaignData } from "@/contexts/campaign-data"
import { CampaignSummary, DailyData, MinimalCampaign } from '@/types/metrics'
import { MetricDisplay } from '@/components/metric-display'
import PMaxSection from '@/components/pmax-trends'
import { MetricKey, METRIC_CONFIGS, useMetricFormatter, calculateDerivedMetrics, getMetricColor } from '@/lib/metrics';
import { useCurrency } from "@/hooks/use-currency"
import { useUser } from '@clerk/clerk-react';

export default function Trends() {
  const { data, loadingStatus, error, useSampleData } = useCampaignData()
  const { isSignedIn } = useUser();
  const dailyData = data?.daily || []
  const [selectedCampaign, setSelectedCampaign] = useState<string>('')
  const [selectedMetrics, setSelectedMetrics] = useState<MetricKey[]>(['Cost', 'ConvValue'])
  const [timeRange, setTimeRange] = useState<'7' | '30' | '90'>('30')
  const [chartType, setChartType] = useState<'line' | 'bar'>('line')
  const { selectedCurrency } = useCurrency()

  const formatMetric = useMetricFormatter()

  // Define available metrics (we'll use this for cards and charts)
  const availableMetrics = useMemo(() =>
    Object.entries(METRIC_CONFIGS)
      .map(([key, config]) => ({
        key: key as MetricKey,
        ...config,
      }))
      .filter(
        (metric) =>
          !['ImprShare', 'LostToBudget', 'LostToRank'].includes(metric.key) // Exclude unwanted metrics
      ),
    []
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short'
    })
  }

  // Get the latest date from the data
  const latestDate = useMemo(() => {
    if (!dailyData?.length) return new Date()
    return new Date(Math.max(...dailyData.map(row => new Date(row.Date).getTime())))
  }, [dailyData])


  const isPMaxCampaign = useMemo(() => {
    if (!selectedCampaign || !data?.pmax_perf?.length) return false;
  
    // Find the matching campaign row
    const pmaxRow = data.pmax_perf.find(row => row.Campaign === selectedCampaign);
  
    // If we find it in pmax_perf data, consider it a PMax campaign
    return !!pmaxRow;
  
  }, [data?.pmax_perf, selectedCampaign]);



  // Filter and enrich data with calculated metrics
  const filteredData = useMemo(() => {
    if (!selectedCampaign) return [];

    const daysToSubtract = parseInt(timeRange, 10);
    const startDate = new Date(latestDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);

    // Filter data based on campaign and date range
    const filtered = dailyData
      .filter((row: DailyData) => {
        const rowDate = new Date(row.Date);
        return (
          row.Campaign === selectedCampaign &&
          rowDate >= startDate &&
          rowDate <= latestDate
        );
      })
      .sort(
        (a: DailyData, b: DailyData) =>
          new Date(a.Date).getTime() - new Date(b.Date).getTime()
      );

    // Map each row to include both base and derived metrics
    return filtered.map((row) => ({
      Date: row.Date, // Keep the date separate for charting
      ...calculateDerivedMetrics(row), // Calculate base and derived metrics
    }));
  }, [dailyData, selectedCampaign, latestDate, timeRange]);


  const campaigns = useMemo(() => {
    if (!dailyData?.length) return [] as MinimalCampaign[];
  
    const campaignMap = new Map<string, MinimalCampaign>();
    
    const daysToSubtract = parseInt(timeRange);
    const startDate = new Date(latestDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);

  dailyData.forEach(row => {
    const rowDate = new Date(row.Date);
    // Only include data within the selected date range
    if (rowDate >= startDate && rowDate <= latestDate) {
      if (!campaignMap.has(row.Campaign)) {
        campaignMap.set(row.Campaign, { Campaign: row.Campaign, Cost: 0 });
      }
      campaignMap.get(row.Campaign)!.Cost += (row.Cost || 0);
    }
  });

  return Array.from(campaignMap.values())
    .sort((a, b) => b.Cost - a.Cost);
}, [dailyData, timeRange, latestDate]);


useEffect(() => {
  console.log('Data source changed, resetting selection');
  setSelectedCampaign('');
}, [useSampleData]);

// Auto-select highest cost campaign when campaign list changes
useEffect(() => {
  if (campaigns.length > 0 && !selectedCampaign) {
    const highestCostCampaign = campaigns[0].Campaign;
    console.log('Auto-selecting campaign:', highestCostCampaign);
    setSelectedCampaign(highestCostCampaign);
    }
  }, [campaigns]);


  // Calculate date range summary
  const dateRangeSummary = useMemo(() => {
    if (!filteredData.length) return 'No dates available'

    const dates = filteredData.map((d) => new Date(d.Date))
    const startDate = new Date(Math.min(...dates.map(d => d.getTime())))
    const endDate = new Date(Math.max(...dates.map(d => d.getTime())))

    return `${startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })} to ${endDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })}`
  }, [filteredData])

  // Calculate totals
  const totals = filteredData.reduce((acc, row) => ({
    // Base metrics
    Impressions: acc.Impressions + (row.Impressions || 0),
    Clicks: acc.Clicks + (row.Clicks || 0),
    Cost: acc.Cost + (row.Cost || 0),
    Conversions: acc.Conversions + (row.Conversions || 0),
    ConvValue: acc.ConvValue + (row.ConvValue || 0),

    // Derived metrics (calculated using base metrics)
    CTR: acc.Impressions + row.Impressions > 0
      ? ((acc.Clicks + row.Clicks) / (acc.Impressions + row.Impressions)) * 100
      : 0,
    CPC: acc.Clicks + row.Clicks > 0
      ? (acc.Cost + row.Cost) / (acc.Clicks + row.Clicks)
      : 0,
    CPA: acc.Conversions + row.Conversions > 0
      ? (acc.Cost + row.Cost) / (acc.Conversions + row.Conversions)
      : 0,
    ROAS: acc.Cost + row.Cost > 0
      ? (acc.ConvValue + row.ConvValue) / (acc.Cost + row.Cost)
      : 0,
    AOV: acc.Conversions + row.Conversions > 0
      ? (acc.ConvValue + row.ConvValue) / (acc.Conversions + row.Conversions)
      : 0,
  }), {
    // Initial values for totals
    Impressions: 0,
    Clicks: 0,
    Cost: 0,
    Conversions: 0,
    ConvValue: 0,
    CTR: 0,
    CPC: 0,
    CPA: 0,
    ROAS: 0,
    AOV: 0,
  });



  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial' 
            ? "Loading your campaign data (this may take a minute or two)..." 
            : "Loading..."}
        </div>
      </div>
    );
  }

  if (!dailyData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No campaign data available. {useSampleData ?
            'Sample data may be incomplete.' :
            'Please check your data source in Settings.'}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    );
  }

  const toggleMetric = (metricKey: MetricKey) => {
    setSelectedMetrics(current => {
      // If metric is already selected, remove it
      if (current.includes(metricKey)) {
        return current.filter(m => m !== metricKey)
      }
      // If less than 2 metrics selected, add the new one
      if (current.length < 2) {
        return [...current, metricKey]
      }
      // If 2 metrics already selected, remove oldest and add new one
      return [current[1], metricKey]
    })
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div className="flex items-center justify-between">
        <Select
          value={selectedCampaign}
          onValueChange={setSelectedCampaign}
        >
          <SelectTrigger className="h-10 w-[500px]">
            <SelectValue placeholder="Select a campaign" />
          </SelectTrigger>
          <SelectContent>
            {campaigns.map(({ Campaign, Cost }) => (
              <SelectItem
                key={Campaign}
                value={Campaign}
                className="flex items-center justify-between py-2 pr-2"
              >
                <span className="truncate mr-4">
                  {Campaign}
                </span>
                <span className="text-sm font-medium text-muted-foreground">
                  {selectedCurrency}{Math.round(Cost).toLocaleString()}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="flex flex-col items-end gap-1">
          <span className="text-xs text-muted-foreground -mb-1">
            {dateRangeSummary}
          </span>
          <Tabs
            defaultValue="30"
            value={timeRange}
            onValueChange={(value) => setTimeRange(value as '7' | '30' | '90')}
          >
            <TabsList className="bg-transparent gap-2">
              <TabsTrigger value="7" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
                7 Days
              </TabsTrigger>
              <TabsTrigger value="30" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
                30 Days
              </TabsTrigger>
              <TabsTrigger value="90" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
                90 Days
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      {/* Metric Cards */}
      <div className="grid grid-cols-10 gap-2">
        {availableMetrics.map(metric => (
          <Card
            key={metric.key}
            onClick={() => toggleMetric(metric.key)}
            className={`cursor-pointer transition-colors hover:bg-accent ${selectedMetrics.includes(metric.key) ? 'ring-2 ring-primary' : ''
              }`}
          >
            <CardHeader className="pb-1 pt-2 px-3 space-y-0">
              <CardTitle className="text-xs font-medium">
                {useSampleData && '(Sample) '}{metric.label}
              </CardTitle>
            </CardHeader>
            <CardContent className="pb-2 px-3">
              <div className="text-lg font-bold">
                {formatMetric(metric.key, totals[metric.key] || 0)}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Main Chart */}
      <div className="space-y-2 mb-8">
        <div className="flex items-center justify-between">
          <CardTitle>
            {useSampleData && '(Sample) '}Campaign Performance Metrics
          </CardTitle>
          <Tabs value={chartType} onValueChange={(value) => setChartType(value as 'line' | 'bar')}>
            <TabsList className="grid w-fit grid-cols-2">
              <TabsTrigger value="line" className="px-4">
                <LineChartIcon className="h-4 w-4 mr-2" />
                Line
              </TabsTrigger>
              <TabsTrigger value="bar" className="px-4">
                <BarChartIcon className="h-4 w-4 mr-2" />
                Bar
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <Card>
          <CardContent className="pt-6">
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                {chartType === 'line' ? (
                  <LineChart data={filteredData}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Date"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickFormatter={formatDate}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey]
                      if (!metric) return null

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                            label={{
                              value: metric.label,
                              angle: -90,
                              position: 'insideLeft',
                              style: { textAnchor: 'middle' }
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey={metricKey}
                            stroke={getMetricColor(index)}
                            yAxisId={index}
                            name={metric.label}
                            dot={false}
                            strokeWidth={2}
                            animationDuration={5}
                          />
                        </React.Fragment>
                      )
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name
                        )
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ]
                      }}
                      labelFormatter={(label: string) => new Date(label).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                    />
                    <Legend />
                  </LineChart>
                ) : (
                  <BarChart data={filteredData} barSize={20}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Date"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickFormatter={formatDate}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey]
                      if (!metric) return null

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                            label={{
                              value: metric.label,
                              angle: -90,
                              position: 'insideLeft',
                              style: { textAnchor: 'middle' }
                            }}
                          />
                          <Bar
                            dataKey={metricKey}
                            fill={getMetricColor(index)}
                            yAxisId={index}
                            name={metric.label}
                            animationDuration={5}
                          />
                        </React.Fragment>
                      )
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name
                        )
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ]
                      }}
                      labelFormatter={(label: string) => new Date(label).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                    />
                    <Legend />
                  </BarChart>
                )}
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* PMax Section */}
      <div className="space-y-2 mt-8">
        {
          isPMaxCampaign ? (
            <PMaxSection
              pmaxData={data.pmax_perf}
              selectedCampaign={selectedCampaign}
              timeRange={timeRange}
              latestDate={latestDate}
            />
          ) : (
            <Card className="bg-muted/50">
              <CardContent className="flex flex-col items-center justify-center py-12 text-center">
                <AlertCircle className="h-12 w-12 text-muted-foreground mb-4" />
                <h3 className="text-lg font-medium mb-2">Performance Channel Analysis</h3>
                <p className="text-muted-foreground">
                  This analysis is only available for Performance Max campaigns.
                  Select a PMax campaign to view detailed channel performance.
                </p>
              </CardContent>
            </Card>
          )
        }
      </div>
    </div>
  )
}