import React from "react";
import { 
  createBrowserRouter, 
  RouterProvider,
  useNavigate,
  useLocation,
  type RouteObject
} from "react-router-dom";

import { useCampaignData, type LoadingStatus } from "@/contexts/campaign-data";
import RootLayout from "@/components/layouts/root-layout";
import { useUser } from "@clerk/clerk-react";


// Import  pages
import Index from "@/pages/index";
import Analysis from "@/pages/analysis";
import Trends from "@/pages/trends";
import Curve from "@/pages/curve";
import Settings from "@/pages/settings";
import ErrorBoundary from '@/components/error-boundary';
import RouteError from '@/route-error';
import Hourly from "@/pages/hourly";
import Scripts from "@/pages/scripts";
import SignInPage from "@/pages/auth/sign-in";
import SignUpPage from "@/pages/auth/sign-up";
import LearnGoogleAdsScripts from "@/pages/scary";
import NotFound from "@/pages/not-found";
import Help from "@/pages/help";

// potentially not needed
import { HelpCircle } from "lucide-react";
import { isConfigured } from "@/lib/constants";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";



function LoadingState() {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { isSignedIn, isLoaded } = useUser();
  const location = useLocation();
  const { useSampleData, loadingStatus } = useCampaignData();
  
  if (!isLoaded || loadingStatus !== 'idle') {
    return <LoadingState />;
  }

  // Settings page does its own auth handling now
  if (location.pathname === '/settings') {
    return <>{children}</>;
  }

  if (useSampleData) {
    return <>{children}</>;
  }

  return <>{children}</>;
}

const routes: RouteObject[] = [
  {
    element: <RootLayout />,
    errorElement: <RouteError />,
    children: [
      {
        path: "/",
        element: <Index />,
        errorElement: <RouteError />
      },
      {
        path: "/settings",
        element: (
          <ErrorBoundary>
            <Settings />
          </ErrorBoundary>
        ),
        errorElement: <RouteError />
      },
      // Public routes
      {
        path: "/analysis",
        element: <Analysis />,
        errorElement: <RouteError />
      },
      {
        path: "/trends",
        element: <Trends />,
        errorElement: <RouteError />
      },
      {
        path: "/curve",
        element: <Curve />,
        errorElement: <RouteError />
      },
      {
        path: "/hourly",
        element: <Hourly />,
        errorElement: <RouteError />
      },
      {
        path: "/scripts",
        element: <Scripts />,
        errorElement: <RouteError />
      },
      {
        path: "/scary",
        element: <LearnGoogleAdsScripts />,
        errorElement: <RouteError />
      },
      {
        path: "/help",
        element: <Help />,
        errorElement: <RouteError />
      },
      // Auth routes
      {
        path: "/sign-in",
        element: <SignInPage />,
        errorElement: <RouteError />
      },
      {
        path: "/sign-up",
        element: <SignUpPage />,
        errorElement: <RouteError />
      },
      {
        path: "*",
        element: <NotFound />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true
  } as const
});

export default function Router() {
  const { useSampleData, loadingStatus } = useCampaignData();
  const { isLoaded: isAuthLoaded } = useUser();
  
  if (loadingStatus !== 'idle' || !isAuthLoaded) {
    return <LoadingState />;
  }
  
  return <RouterProvider router={router} />;
}