// src/pages/settings.tsx
'use client'

import { useEffect, useState } from "react";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { Progress } from "@/components/ui/progress";
import { Switch } from "@/components/ui/switch";
import { Loader2, AlertCircle, CheckCircle2, ExternalLink, Copy, CheckCircle } from "lucide-react";
import { Save, Zap, Lock, BarChart3, Rocket, ChevronRight } from "lucide-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Steps } from "@/components/ui/steps";
import { STORAGE_KEYS, DATA_SOURCES, CACHE_DURATION, CURRENCIES } from "@/lib/constants";
import { TabKey } from "@/lib/constants";
import { useCampaignData, type LoadingStatus } from "@/contexts/campaign-data";
import { useCurrency } from "@/hooks/use-currency";
import { getGoogleAdsScript } from "@/lib/google-ads-script-template";
import { useUser } from "@clerk/clerk-react";
import { StorageData, APP_VERSION } from "@/types/metrics";
import { useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useSampleDataMode } from "@/hooks/use-sample-data-mode";

const TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/1XmQYHWYbQbtn6mnrGYB71dsixbp66Eo3n5o7fka_s10/copy";

// Log app version
console.log('App Version:', APP_VERSION);

interface FetchStatusType {
  loadingStatus: LoadingStatus;
  rowCount?: number;
  error?: string;
  lastUpdated?: string;
}

interface BenefitCard {
  title: string
  description: string
  icon: React.ComponentType<{ className?: string }>
}

interface BusinessModeToggleProps {
  isEcommerceMode: boolean;
  onModeChange: (value: boolean) => void;
}

function BusinessModeToggle({ isEcommerceMode, onModeChange }: BusinessModeToggleProps) {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        checked={isEcommerceMode}
        onCheckedChange={onModeChange}
      />
      <span className="text-sm">
        {isEcommerceMode ? 'E-commerce' : 'Lead Generation'}
      </span>
    </div>
  );
}

function SampleDataToggle() {
  const { toast } = useToast();
  const { useSampleData, switchToSampleData } = useCampaignData();
  const { isSignedIn } = useUser();

  const handleSampleDataChange = async (value: boolean) => {
    try {
      await switchToSampleData(!value); // Invert because switch OFF means use sample data
      toast({
        title: "Success",
        description: `Switched to ${!value ? 'sample' : 'your'} data`,
      });
    } catch (error) {
      console.error('Sample data update error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to switch data source",
      });
    }
  };

  if (!isSignedIn) return null;

  return (
    <div className="flex items-center space-x-2">
      <Switch
        checked={!useSampleData} // Invert because OFF means use sample data
        onCheckedChange={handleSampleDataChange}
      />
      <span className="text-sm">
        {useSampleData ? 'Using Sample Data' : 'Using Your Data'}
      </span>
    </div>
  );
}

// Currency Selector Component
function CurrencySelector({ selectedCurrency, onCurrencyChange }) {
  return (
    <div className="flex items-center space-x-2">
      <Select value={selectedCurrency} onValueChange={onCurrencyChange}>
        <SelectTrigger className="w-48">
          <SelectValue placeholder="Select currency" />
        </SelectTrigger>
        <SelectContent>
          {CURRENCIES.map((currency) => (
            <SelectItem key={currency.symbol} value={currency.symbol}>
              {currency.label} ({currency.symbol})
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

// Data source status component
function DataSourceStatus({ fetchStatus }: { fetchStatus: Record<string, FetchStatusType> }) {
  const { useSampleData } = useCampaignData();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Status of Data Sources</CardTitle>
        <CardDescription>Current status of all data sources</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Source</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Last Update</TableHead>
              <TableHead>Records</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
  {Object.entries(DATA_SOURCES).map(([tab, source]) => {
    const status = fetchStatus[tab] || {
      loadingStatus: 'idle' as LoadingStatus,
      rowCount: 0,
      error: undefined,
      lastUpdated: undefined
    };

    return (
      <TableRow key={tab}>
        <TableCell className="font-medium">
          <div>
            <div>{source.title}</div>
            <div className="text-xs text-muted-foreground">{source.description}</div>
          </div>
        </TableCell>
        <TableCell>
          {status.error ? (
            <div className="flex items-center">
              <AlertCircle className="h-4 w-4 text-destructive mr-2" />
              <span className="text-xs text-destructive">Error</span>
            </div>
          ) : status.loadingStatus !== 'idle' ? (
            <div className="flex items-center">
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
              <span className="text-xs">
                {status.loadingStatus === 'initial' ? 'Loading...' : 
                  status.loadingStatus === 'refresh' ? 'Updating...' : 
                  'Switching...'}
              </span>
            </div>
          ) : status.rowCount > 0 ? (
            <div className="flex items-center">
              <CheckCircle2 className="h-4 w-4 text-green-500 mr-2" />
              <span className="text-xs text-green-600">Ready</span>
            </div>
          ) : (
            <div className="flex items-center">
              <AlertCircle className="h-4 w-4 text-muted-foreground mr-2" />
              <span className="text-xs text-muted-foreground">No data</span>
            </div>
          )}
        </TableCell>
        <TableCell>
          {useSampleData ? (
            <span className="text-sm text-muted-foreground">Sample Data</span>
          ) : status.lastUpdated ? (
            <div className="text-sm">
              {new Date(status.lastUpdated).toLocaleString()}
            </div>
          ) : (
            <span className="text-sm text-muted-foreground">Never</span>
          )}
        </TableCell>
        <TableCell>
          <div className="text-sm">
            {status.rowCount?.toLocaleString() || '0'}
          </div>
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}


// Main Settings Page Component
export default function Settings() {
  const navigate = useNavigate();
  const { user, isSignedIn } = useUser();
  const isEcommerceMode = (user?.unsafeMetadata?.isEcommerceMode as boolean) ?? true;
  const { toast } = useToast();
  const {
    refreshData,
    data,
    loadingStatus,
    saveWebAppUrl,
    getWebAppUrl,
    error: dataError,
    useSampleData
  } = useCampaignData();

  const { selectedCurrency, setSelectedCurrency } = useCurrency();

  const [setupState, setSetupState] = useState({
    sheetUrl: '',
    webAppUrl: '',
    currentStep: 1,
    scriptCopied: false
  });
  const [showSetupWizard, setShowSetupWizard] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [fetchStatus, setFetchStatus] = useState({});

  type SetupStep = {
    number: number;
    title: string;
    description: string;
    instructions: React.ReactNode;
  };

  useEffect(() => {
    const newStatus = {};
    Object.entries(DATA_SOURCES).forEach(([tab, _]) => {
      const tabData = data ? data[tab] : null;
      newStatus[tab] = {
        loadingStatus,  // Use the new loadingStatus
        rowCount: Array.isArray(tabData) ? tabData.length : 0,
        lastUpdated: new Date().toISOString(),
        error: undefined
      };
    });
    setFetchStatus(newStatus);
  }, [data, loadingStatus]);  // Dependency changed to loadingStatus


  const copyScript = async () => {
    try {
      const script = getGoogleAdsScript(setupState.sheetUrl);
      await navigator.clipboard.writeText(script);
      setSetupState(prev => ({ ...prev, scriptCopied: true }));
      toast({
        title: "Success",
        description: "Script copied to clipboard",
      });
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to copy script",
      });
    }
  };

  const handleWebAppUrlUpdate = async (url) => {
    if (!url.includes('macro')) {
      toast({
        variant: "destructive",
        title: "Invalid URL",
        description: "Please enter a valid Google Apps Script Web App URL",
      });
      return;
    }

    setIsValidating(true);
    try {
      await saveWebAppUrl(url);
      await refreshData();
      setShowSetupWizard(false);
      toast({
        title: "Success",
        description: "Web App URL updated successfully",
      });
    } catch (error) {
      console.error('URL update error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update Web App URL",
      });
    } finally {
      setIsValidating(false);
    }
  };

  const handleModeChange = async (value: boolean) => {
    try {
      if (!user) return;

      await user.update({
        unsafeMetadata: {
          ...user.unsafeMetadata,
          isEcommerceMode: value,
        },
      });

      toast({
        title: "Success",
        description: `Switched to ${value ? 'E-commerce' : 'Lead Generation'} mode`,
      });
    } catch (error) {
      console.error('Mode update error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update mode",
      });
    }
  };

  // Marketing view for non-signed-in users
  if (!isSignedIn && !useSampleData) {
    const benefits: BenefitCard[] = [
      {
        title: "Instant Setup",
        description: "Connect your Google Ads data in minutes with our step-by-step setup wizard",
        icon: Rocket
      },
      {
        title: "Save Your Settings",
        description: "Your Web App URL, COGS value, and preferences are remembered automatically",
        icon: Save
      },
      {
        title: "Real-Time Updates",
        description: "Get hourly updates of your campaign performance and optimization suggestions",
        icon: Zap
      },
      {
        title: "Secure Access",
        description: "Your data is securely stored and accessible only to you",
        icon: Lock
      }
    ];

    return (
      <div className="container mx-auto py-6 space-y-6">
        <Card>
          <CardHeader>
            <CardTitle>Sign in Required</CardTitle>
            <CardDescription>
              Please sign in or create an account to configure your campaign settings
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="grid gap-6 md:grid-cols-2">
              {benefits.map((benefit) => (
                <Card key={benefit.title} className="bg-muted/50">
                  <CardContent className="pt-6">
                    <div className="flex items-center gap-4">
                      <benefit.icon className="h-8 w-8 text-primary" />
                      <div>
                        <h3 className="font-semibold">{benefit.title}</h3>
                        <p className="text-sm text-muted-foreground">{benefit.description}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>

            <div className="flex flex-col sm:flex-row gap-4 justify-center pt-4">
              <Button
                size="lg"
                onClick={() => navigate('/sign-up')}
                className="flex items-center gap-2"
              >
                Create Free Account
                <ChevronRight className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="lg"
                onClick={() => navigate('/sign-in')}
              >
                Sign In
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  const steps: SetupStep[] = [
    {
      number: 1,
      title: "Create Sheet",
      description: "Copy template",
      instructions: (
        <div className="space-y-4">
          <p>First, create a copy of the template sheet that will store your campaign data</p>
          <p>Then paste the URL of your sheet in the space below & click Continue.</p>
          <Button
            onClick={() => window.open(TEMPLATE_URL, '_blank')}
            className="flex items-center space-x-2"
          >
            <ExternalLink className="h-4 w-4" />
            <span>Create Copy of Template</span>
          </Button>
          <Input
            type="url"
            value={setupState.sheetUrl}
            onChange={(e) => setSetupState(prev => ({
              ...prev,
              sheetUrl: e.target.value
            }))}
            placeholder="Paste your sheet URL here"
          />
          <Button
            onClick={() => setSetupState(prev => ({ ...prev, currentStep: 2 }))}
            disabled={!setupState.sheetUrl.includes('docs.google.com/spreadsheets')}
          >
            Continue
          </Button>
        </div>
      )
    },
    {
      number: 2,
      title: "Install Script",
      description: "Set up Google Ads",
      instructions: (
        <div className="space-y-4">
          <p>The script has been created with your sheet url, all you have to do is copy & paste.</p>
          <p>(If you need help click 'View Instructions' or for a mini-course, try <a href="/scary">Scary</a> above)</p>
          <p>Paste this script in your Google Ads account & run it once to verify it works.</p>
          <p>You'll see the sheet fill with data. Then schedule it to run hourly, and click Continue.</p>
          <div className="flex space-x-2">
            <Button
              onClick={copyScript}
              disabled={setupState.scriptCopied}
              className="flex items-center space-x-2"
            >
              {setupState.scriptCopied ? (
                <CheckCircle className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              <span>{setupState.scriptCopied ? 'Copied!' : 'Copy Script'}</span>
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowInstructions(true)}
            >
              View Instructions
            </Button>
          </div>
          <Button
            onClick={() => setSetupState(prev => ({ ...prev, currentStep: 3 }))}
            disabled={!setupState.scriptCopied}
          >
            Continue
          </Button>
        </div>
      )
    },
    {
      number: 3,
      title: "Deploy Web App",
      description: "Connect your data",
      instructions: (
        <div className="space-y-4">
          <p>Finally, 'deploy' your Sheet so this app can read the data</p>
          <ol className="list-decimal list-inside space-y-2">
            <li>Open your version of the sheet</li>
            <li>Click Extensions → Apps Script (next to Help in the main menu)</li>
            <li>Click Deploy → New deployment (top right corner)</li>
            <li>Set description to 'Mike Rhodes App'</li>
            <li>Leave 'execute as' and 'who has access' unchanged</li>
            <li>Click 'Deploy' and authorize when prompted</li>
            <li>Copy the Web App URL & paste below. It's the one that starts with https://script.google.com/../..</li>
            <li>(Note: if you accidently close that window, click 'Deploy' then 'Manage' to find your URL again)</li>
          </ol>
          <Input
            type="url"
            value={setupState.webAppUrl}
            onChange={(e) => setSetupState(prev => ({
              ...prev,
              webAppUrl: e.target.value
            }))}
            placeholder="Paste Web App URL here"
          />
          <Button
            onClick={() => handleWebAppUrlUpdate(setupState.webAppUrl)}
            disabled={!setupState.webAppUrl.includes('macro') || isValidating}
          >
            {isValidating ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                Validating & Loading Initial Data (might take a minute)
              </>
            ) : (
              'Complete Setup'
            )}
          </Button>
        </div>
      )
    }
  ];

  // Update DebugInfo component to use getWebAppUrl:
  function DebugInfo() {
    const { user, isSignedIn } = useUser();
    const { useSampleData, lastUpdated, getWebAppUrl } = useCampaignData();
    const isEcommerceMode = (user?.unsafeMetadata?.isEcommerceMode as boolean) ?? true;

    if (process.env.NODE_ENV !== 'development') return null;

    return (
      <Card className="mt-8">
        <CardHeader>
          <CardTitle>Debug Info</CardTitle>
        </CardHeader>
        <CardContent>
          <pre className="text-xs whitespace-pre-wrap bg-muted p-4 rounded-md">
            {JSON.stringify({
              usingSampleData: useSampleData,
              isSignedIn,
              webAppUrl: user?.unsafeMetadata.webAppUrl,
              lastUpdated,
              cogsAmount: user?.unsafeMetadata.cogsAmount,
              currentGameLevel: user?.unsafeMetadata.currentGameLevel,
              mode: isEcommerceMode ? 'E-commerce' : 'Lead Generation'
            }, null, 2)}
          </pre>
        </CardContent>
      </Card>
    );
  }

  // Show setup wizard if no Web App URL is configured or if user wants to change it
  const currentWebAppUrl = getWebAppUrl();
  if (!currentWebAppUrl || showSetupWizard) {
    return (
      <div className="container mx-auto py-6 space-y-6">
        <Card>
          <CardHeader>
            <CardTitle>Setup Wizard</CardTitle>
            <CardDescription>Let's get your campaign data connected</CardDescription>
          </CardHeader>
          <CardContent className="space-y-8">
            {steps.map((step) => (
              <div
                key={step.number}
                className={`space-y-4 ${step.number !== setupState.currentStep ? 'opacity-50' : ''}`}
              >
                <div className="flex items-start gap-4">
                  <div className={cn(
                    "flex h-8 w-8 shrink-0 items-center justify-center rounded-full border text-center",
                    step.number === setupState.currentStep && "bg-primary text-primary-foreground border-primary",
                    step.number !== setupState.currentStep && "border-muted-foreground"
                  )}>
                    {step.number}
                  </div>
                  <div>
                    <h3 className="font-semibold">{step.title}</h3>
                    <p className="text-sm text-muted-foreground">{step.description}</p>
                  </div>
                </div>

                {/* Step Instructions */}
                {step.number === setupState.currentStep && (
                  <div className="ml-12">
                    {step.instructions}
                  </div>
                )}
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    );
  }



  // Regular settings view for configured users
  return (
    <div className="container mx-auto py-6 space-y-6">
      {/* Data Config Card */}
      <Card>
        <CardHeader>
          <div className="flex justify-between items-start">
            <div>
              <CardTitle>Data & Settings Configuration</CardTitle>
              <CardDescription>Manage your data source settings</CardDescription>
            </div>
            <span className="text-xs text-muted-foreground">v{APP_VERSION}</span>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Web App URL Section - only show when using real data */}
          {isSignedIn && !useSampleData && (
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Web App URL</p>
                <p className="text-sm text-muted-foreground">
                  {currentWebAppUrl ? 'Connected' : 'Not configured'}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <Button variant="outline" onClick={() => setShowSetupWizard(true)}>
                  Change URL
                </Button>
                <Button
                  onClick={() => refreshData()}
                  disabled={loadingStatus !== 'idle'}
                >
                  {loadingStatus !== 'idle' ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      {loadingStatus === 'initial' ? 'Loading Initial Data...' : 'Updating...'}
                    </>
                  ) : (
                    'Update Data'
                  )}
                </Button>
              </div>
            </div>
          )}

          {/* Currency Section */}
          <div className="flex items-center justify-between border-t pt-6">
            <div>
              <p className="font-medium">Currency Setting</p>
              <p className="text-sm text-muted-foreground">
                Select your preferred currency for reports
              </p>
            </div>
            <CurrencySelector
              selectedCurrency={selectedCurrency}
              onCurrencyChange={setSelectedCurrency}
            />
          </div>

          {/* Mode Toggle Section - only show in development */}
          {process.env.NODE_ENV === 'development' && (
            <div className="flex items-center justify-between border-t pt-6">
              <div>
                <p className="font-medium">Business Mode</p>
                <p className="text-sm text-muted-foreground">
                  Select between E-commerce and Lead Generation
                </p>
              </div>
              <BusinessModeToggle
                isEcommerceMode={isEcommerceMode}
                onModeChange={handleModeChange}
              />
            </div>
          )}

          {/* Sample Data Toggle Section */}
          {isSignedIn && (
            <div className="flex items-center justify-between border-t pt-6">
              <div>
                <p className="font-medium">Data Source</p>
                <p className="text-sm text-muted-foreground">
                  Choose between sample data or your campaign data
                </p>
              </div>
              <SampleDataToggle />
            </div>
          )}
        </CardContent>
      </Card>

      {/* Loading State */}
      {loadingStatus !== 'idle' && (
        <Card>
          <CardContent className="flex flex-col items-center justify-center py-6">
            <div className="text-center space-y-4">
              <Loader2 className="h-6 w-6 animate-spin mx-auto" />
              <div>
                <p className="font-medium">
                  {loadingStatus === 'initial'
                    ? "Loading your campaign data..."
                    : loadingStatus === 'refresh'
                      ? "Updating your data..."
                      : "Switching data source..."}
                </p>
                {loadingStatus === 'initial' && (
                  <p className="text-sm text-muted-foreground">
                    This may take a minute or two...
                  </p>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {/* Data Source Status */}
      <DataSourceStatus fetchStatus={fetchStatus} />

      {/* Error State */}
      {dataError && (
        <Alert variant="destructive">
          <AlertDescription>{dataError}</AlertDescription>
        </Alert>
      )}

      {/* Debug Info */}
      <DebugInfo />
    </div>
  );
}