// src/services/db.ts
import Dexie, { Table } from 'dexie';
import { StorageData } from '@/types/metrics';

const DEBUG = process.env.NODE_ENV === 'development';
const HOUR = 60 * 60 * 1000;

const log = (action: string, details?: any) => {
  if (DEBUG) {
    console.log(`%c[Dexie ${action}]`, 'color: #8b5cf6', details || '');
  }
};

export interface DataStore extends StorageData {
  id?: number;
  lastUpdated: string;
  isSampleData: number;
}

export class PMMaxDatabase extends Dexie {
  campaignData!: Table<DataStore>;

  constructor() {
    super('pmaxData');
    log('Init', 'Database created');
    this.version(1).stores({
      campaignData: '++id,lastUpdated,isSampleData'
    });
  }

  /**
   * Get current data based on the data mode (sample or user)
   */
  async getCurrentData(useSampleData: boolean): Promise<DataStore | undefined> {
    const data = await this.campaignData
      .where('isSampleData')
      .equals(useSampleData ? 1 : 0)
      .last();

    log('Read Current Data', {
      type: useSampleData ? 'sample' : 'user',
      timestamp: data?.lastUpdated,
      isSample: data?.isSampleData === 1,
      dataSize: data ? JSON.stringify(data).length / 1024 + 'KB' : 0
    });
    return data;
  }

  /**
   * Get most recent user data
   */
  async getUserData(): Promise<DataStore | undefined> {
    const data = await this.campaignData
      .where('isSampleData')
      .equals(0)
      .last();

    log('Read User Data', {
      timestamp: data?.lastUpdated,
      dataSize: data ? JSON.stringify(data).length / 1024 + 'KB' : 0
    });
    return data;
  }

  /**
   * Get most recent sample data
   */
  async getSampleData(): Promise<DataStore | undefined> {
    const data = await this.campaignData
      .where('isSampleData')
      .equals(1)
      .last();

    log('Read Sample Data', {
      timestamp: data?.lastUpdated,
      dataSize: data ? JSON.stringify(data).length / 1024 + 'KB' : 0
    });
    return data;
  }

  /**
   * Save new data, clearing existing data of the same type first
   */
  async saveData(data: StorageData, isSampleData: boolean = false): Promise<void> {
    // Clear existing data of the same type before saving
    if (isSampleData) {
      await this.clearSampleData();
    } else {
      await this.clearUserData();
    }

    const dataToStore: DataStore = {
      ...data,
      lastUpdated: new Date().toISOString(),
      isSampleData: isSampleData ? 1 : 0
    };

    log('Write', {
      operation: 'save',
      isSample: isSampleData,
      timestamp: dataToStore.lastUpdated,
      dataSize: JSON.stringify(dataToStore).length / 1024 + 'KB'
    });

    await this.campaignData.add(dataToStore);

    // Verify the save was successful
    const savedData = await this.campaignData
      .where('lastUpdated')
      .equals(dataToStore.lastUpdated)
      .first();

    if (!savedData) {
      throw new Error('Failed to save data to database');
    }
  }

  /**
   * Clear all user data from the database
   */
  async clearUserData(): Promise<void> {
    log('Clear', 'Clearing user data');
    const count = await this.campaignData
      .where('isSampleData')
      .equals(0)
      .delete();

    log('Clear Complete', {
      type: 'user',
      recordsDeleted: count
    });
  }

  /**
   * Clear all sample data from the database
   */
  async clearSampleData(): Promise<void> {
    log('Clear', 'Clearing sample data');
    const count = await this.campaignData
      .where('isSampleData')
      .equals(1)
      .delete();

    log('Clear Complete', {
      type: 'sample',
      recordsDeleted: count
    });
  }

  /**
   * Clear all data from the database
   */
  async clearAllData(): Promise<void> {
    log('Clear', 'Clearing all data');
    await this.campaignData.clear();
    log('Clear Complete', {
      type: 'all'
    });
  }

  /**
   * Check if data is older than one hour
   */
  isDataStale(data: DataStore | undefined): boolean {
    if (!data) return true;
    const lastUpdate = new Date(data.lastUpdated).getTime();
    const now = Date.now();
    const age = now - lastUpdate;
    
    log('Stale Check', {
      lastUpdate: new Date(lastUpdate).toISOString(),
      now: new Date(now).toISOString(),
      age: `${Math.round(age / 1000 / 60)} minutes`,
      isStale: age > HOUR
    });
    
    return age > HOUR;
  }

  /**
   * Get total count of records by type
   */
  async getRecordCount(isSampleData: boolean): Promise<number> {
    return await this.campaignData
      .where('isSampleData')
      .equals(isSampleData ? 1 : 0)
      .count();
  }

  /**
   * Delete old records keeping only the most recent for each type
   */
  async pruneOldRecords(): Promise<void> {
    // Get most recent records
    const latestUser = await this.getUserData();
    const latestSample = await this.getSampleData();

    if (latestUser) {
      // Delete all user records except the most recent
      await this.campaignData
        .where('isSampleData')
        .equals(0)
        .and(item => item.lastUpdated !== latestUser.lastUpdated)
        .delete();
    }

    if (latestSample) {
      // Delete all sample records except the most recent
      await this.campaignData
        .where('isSampleData')
        .equals(1)
        .and(item => item.lastUpdated !== latestSample.lastUpdated)
        .delete();
    }

    log('Prune', 'Old records deleted');
  }

  /**
   * Get database statistics
   */
  async getStats(): Promise<{
    userRecords: number;
    sampleRecords: number;
    totalSize: number;
  }> {
    const [userRecords, sampleRecords] = await Promise.all([
      this.getRecordCount(false),
      this.getRecordCount(true)
    ]);

    const allData = await this.campaignData.toArray();
    const totalSize = JSON.stringify(allData).length / 1024; // KB

    log('Stats', {
      userRecords,
      sampleRecords,
      totalSize: `${totalSize.toFixed(2)}KB`
    });

    return {
      userRecords,
      sampleRecords,
      totalSize
    };
  }
}

export const db = new PMMaxDatabase();