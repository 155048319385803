// src/components/sample-data-banner.tsx
import { useCampaignData } from "@/contexts/campaign-data"
import { AlertCircle } from "lucide-react"

export const SampleDataBanner = () => {
  const { useSampleData } = useCampaignData()

  if (!useSampleData) return null

  return (
    <div className="bg-orange-100/80 dark:bg-orange-900/40 border-b border-orange-200 dark:border-orange-800/60">
      <div className="container flex items-center gap-2 px-4 py-2 text-sm text-orange-800 dark:text-orange-200">
        <AlertCircle className="h-4 w-4" />
        <p>Currently viewing sample data - Sign Up to see your own data (or Sign In if you already have an account)</p>
      </div>
    </div>
  )
}