// src/components/layouts/root-layout.tsx
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar } from '@/components/navigation/navbar';
import { useUser } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';
import { useCampaignData, type LoadingStatus } from '@/contexts/campaign-data';
import { isConfigured } from '@/lib/constants';
import { Progress } from '@/components/ui/progress';
import { SampleDataBanner } from '@/components/sample-data-banner';

export default function RootLayout() {
  const { user, isLoaded } = useUser();
  const { refreshData, data, loadingStatus, useSampleData } = useCampaignData();
  const [hasInitialized, setHasInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isLoaded && user && !hasInitialized) {
      const webAppUrl = user.unsafeMetadata.webAppUrl as string;
      if (webAppUrl && !data && !useSampleData) {
        refreshData();
        setHasInitialized(true);
      }
    }
  }, [isLoaded, user, refreshData, data, hasInitialized, useSampleData]);

  useEffect(() => {
    if (!user && hasInitialized) {
      setHasInitialized(false);
    }
  }, [user]);

  const showLoading = loadingStatus !== 'idle' || 
    (isLoaded && user && !hasInitialized && !useSampleData);

  return (
    <div className="relative min-h-screen bg-background font-sans antialiased">
      <div className="relative flex min-h-screen flex-col">
        <Navbar />
        {showLoading && (
          <div className="fixed top-0 left-0 w-full z-50">
            <Progress value={undefined} className="w-full h-1" />
          </div>
        )}
        <main className="flex-1 container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-8">
          <SampleDataBanner />
          <Outlet />
        </main>
      </div>
    </div>
  );
}