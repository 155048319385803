// src/components/budget-optimization/use-campaign-projections.ts

import { useState, useMemo, useEffect } from 'react';
import { Campaign, Recommendation } from '@/lib/profit-utils';
import { calculateProfitProjections, findOptimalZone, getRecommendation, projectMetrics } from '@/lib/profit-utils';
import { OptimizationMode, RowAdjustment, CampaignProjection } from './types';

export function useCampaignProjections(
  campaigns: Campaign[],
  cogsPercentage: number,
  optimizationMode: OptimizationMode,
  includeFilter: string,
  excludeFilter: string
) {
  const [rowAdjustments, setRowAdjustments] = useState<Record<string, RowAdjustment>>({});
  const [lastMode, setLastMode] = useState<OptimizationMode>(optimizationMode);

  // Filter campaigns based on search criteria
  const filteredCampaigns = useMemo(() => {
    return campaigns.filter(campaign => {
      const name = campaign.Campaign?.toLowerCase() || '';
      const include = includeFilter?.toLowerCase() || '';
      const exclude = excludeFilter?.toLowerCase() || '';

      if (include && !name.includes(include)) return false;
      if (exclude && name.includes(exclude)) return false;

      return true;
    });
  }, [campaigns, includeFilter, excludeFilter]);

  // Calculate campaign projections
  const campaignProjections = useMemo((): CampaignProjection[] => {
    return filteredCampaigns.map(campaign => {
      const profitData = calculateProfitProjections({
        currentCost: campaign.Cost,
        currentRevenue: campaign.ConvValue,
        conversions: campaign.Conversions,
        cogsPercentage,
        impressionShare: campaign.ImprShare
      });

      const optimalZone = findOptimalZone(profitData, campaign.Cost);
      const { recommendation, recommendationDetail, adjustmentPercent }: Recommendation =
        getRecommendation(campaign, profitData, optimalZone);

      // Get current adjustment
      const currentAdjustment: number = rowAdjustments[campaign.Campaign]?.adjustment ?? 0;

      // Calculate projected metrics
      const {
        projectedCost,
        projectedClicks,
        projectedConversions,
        projectedRevenue,
        projectedProfit,
        projectedImpressions,
        profitChange,
        percentChange,
        projectedIS
      } = projectMetrics(campaign, currentAdjustment, cogsPercentage);

      return {
        name: campaign.Campaign,
        currentCost: campaign.Cost,
        currentProfit: campaign.ConvValue * (1 - cogsPercentage / 100) - campaign.Cost,
        currentConversions: campaign.Conversions,
        currentClicks: campaign.Clicks,
        projectedCost,
        projectedProfit,
        projectedConversions,
        projectedClicks,
        projectedImpressions,
        projectedRevenue,
        percentChange,
        profitChange,
        currentIS: campaign.ImprShare * 100,
        projectedIS: projectedIS * 100,
        changeReason: recommendationDetail,
        optimalMin: optimalZone.start,
        optimalMax: optimalZone.end,
        isHighIS: campaign.ImprShare >= 0.9,
        recommendation
      };
    });
  }, [filteredCampaigns, cogsPercentage, rowAdjustments]);

  // Handle optimization mode changes
  useEffect(() => {
    if (lastMode === optimizationMode) {
        return;
    }

    // Reset adjustments when switching to 'none'
    if (optimizationMode === 'none') {
        setRowAdjustments({});
        setLastMode(optimizationMode);
        return;
    }

    const newAdjustments: Record<string, RowAdjustment> = {};

    campaignProjections.forEach(projection => {
        // Don't calculate adjustments for high IS campaigns or those within optimal range
        if (projection.isHighIS || 
            (projection.currentCost >= projection.optimalMin && 
             projection.currentCost <= projection.optimalMax )) {
            newAdjustments[projection.name] = {
                campaignName: projection.name,
                adjustment: 0
            };
            return;
        }

        // Calculate new adjustment based on the mode
        const adjustment = getInitialAdjustment(projection, optimizationMode);
        newAdjustments[projection.name] = {
            campaignName: projection.name,
            adjustment
        };
    });

    setRowAdjustments(newAdjustments);
    setLastMode(optimizationMode);
}, [optimizationMode, campaignProjections]);

  const projectedMetrics = useMemo(() => {
    // Calculate current totals
    const current = {
      cost: 0,
      revenue: 0,
      profit: 0,
      conversions: 0,
      clicks: 0,
      impressions: 0
    };

    // Calculate projected totals
    const projected = {
      cost: 0,
      revenue: 0,
      profit: 0,
      conversions: 0,
      clicks: 0,
      impressions: 0
    };

    // Sum up current metrics
    filteredCampaigns.forEach(campaign => {
      current.cost += campaign.Cost || 0;
      current.revenue += campaign.ConvValue || 0;
      current.conversions += campaign.Conversions || 0;
      current.clicks += campaign.Clicks || 0;
      current.impressions += campaign.Impressions || 0;
    });

    // Calculate projected metrics including adjustments
    filteredCampaigns.forEach(campaign => {
      const adjustment = rowAdjustments[campaign.Campaign]?.adjustment ?? 0;
      const metrics = projectMetrics(campaign, adjustment, cogsPercentage);

      projected.cost += metrics.projectedCost;
      projected.revenue += metrics.projectedRevenue;
      projected.conversions += metrics.projectedConversions;
      projected.clicks += metrics.projectedClicks;
      projected.impressions += metrics.projectedImpressions;
    });

    // Calculate profits
    current.profit = current.revenue * (1 - cogsPercentage / 100) - current.cost;
    projected.profit = projected.revenue * (1 - cogsPercentage / 100) - projected.cost;

    return { current, projected };
  }, [filteredCampaigns, rowAdjustments, cogsPercentage]);

  return {
    campaignProjections,
    projectedMetrics,
    rowAdjustments,
    setRowAdjustments
  };
}

function getInitialAdjustment(projection: CampaignProjection, mode: OptimizationMode): number {
  if (projection.isHighIS || mode === 'none') {
    return 0;
  }

  const multiplier = mode === 'conservative' ? 0.5 :
    mode === 'balanced' ? 1.0 :
      mode === 'aggressive' ? 1.5 : 0;

  if (projection.currentProfit <= 0) {
    return mode === 'conservative' ? -25 :
      mode === 'balanced' ? -50 :
        mode === 'aggressive' ? -75 : 0;
  }

  if (projection.currentCost < projection.optimalMin) {
    const percentBelow = ((projection.optimalMin - projection.currentCost) / projection.currentCost) * 100;
    return Math.min(100, Math.round(percentBelow * multiplier));
  }

  if (projection.currentCost > projection.optimalMax) {
    const percentAbove = ((projection.currentCost - projection.optimalMax) / projection.currentCost) * 100;
    return Math.max(-100, Math.round(-percentAbove * multiplier));
  }

  const positionInRange = (projection.currentCost - projection.optimalMin) /
    (projection.optimalMax - projection.optimalMin);
  if (positionInRange < 0.4) {
    return Math.round(10 * multiplier);
  } else if (positionInRange > 0.6) {
    return Math.round(-10 * multiplier);
  }

  return 0;
}

// Add safety checks in the metrics calculations
const calculateProjectedMetrics = (projections: CampaignProjection[]) => {
    const metrics = projections.reduce((acc, proj) => ({
        cost: (acc.cost || 0) + (isNaN(proj.projectedCost) ? 0 : proj.projectedCost),
        revenue: (acc.revenue || 0) + (isNaN(proj.projectedRevenue) ? 0 : proj.projectedRevenue),
        profit: (acc.profit || 0) + (isNaN(proj.projectedProfit) ? 0 : proj.projectedProfit),
        conversions: (acc.conversions || 0) + (isNaN(proj.projectedConversions) ? 0 : proj.projectedConversions),
        clicks: (acc.clicks || 0) + (isNaN(proj.projectedClicks) ? 0 : proj.projectedClicks)
    }), {
        cost: 0,
        revenue: 0,
        profit: 0,
        conversions: 0,
        clicks: 0
    });

    return metrics;
};