// src/pages/curve.tsx
'use client'

import { useState, useEffect, useMemo } from 'react';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip,
  ResponsiveContainer, ReferenceLine, ReferenceArea
} from 'recharts';
import { ChartThumbnail } from "@/components/chart-thumbnail";
import { useCampaignData } from "@/contexts/campaign-data";
import {
  calculateProfitProjections,
  findOptimalZone,
  type Campaign,
  type OptimalZone,
  type ProfitDataPoint
} from '@/lib/profit-utils';
import { useCurrency } from "@/hooks/use-currency";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useUser } from '@clerk/clerk-react';
type ChartType = 'profit-curve' | 'incremental-profit' | 'profit-vs-roas' | 'marginal-roas';
type ResponseModel = 'diminishing' | 'linear' | 'independent';

type IncrementalDataPoint = {
  cost: number;
  incrementalProfit: number;
  incrementalSales: number;
  incrementalROAS: number;
}

interface ExtendedChartConfig {
  dataKey: string;
  stroke: string;
  data: ProfitDataPoint[] | IncrementalDataPoint[];
  label: string;
  tooltipFormatter: (value: number) => string;
}

export default function ProfitAnalysis() {
  const { data, loadingStatus, error, cogsAmount, updateCogsAmount } = useCampaignData();
  const { isSignedIn } = useUser();
  const thirtyDayData = data?.thirty_days || [];
  const { selectedCurrency } = useCurrency();
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [cost, setCost] = useState(0);
  const [convValue, setConvValue] = useState(0);
  const [cogsPercentage, setCogsPercentage] = useState(cogsAmount ?? 50);
  const [activeChart, setActiveChart] = useState<ChartType>('profit-curve');
  const [optimalZone, setOptimalZone] = useState<OptimalZone>({
    start: 0,
    end: 0,
    current: 0,
    maxProfit: 0
  });
  const [baseMetrics, setBaseMetrics] = useState({ cost: 0, revenue: 0 });
  const [costRange, setCostRange] = useState({ min: 0, max: 0 });
  const [revenueRange, setRevenueRange] = useState({ min: 0, max: 0 });
  const [responseModel, setResponseModel] = useState<ResponseModel>('diminishing');

  // Update the calculateRevenueFromCost function to handle different models
  const calculateRevenueFromCost = (
    currentCost: number,
    baseCost: number,
    baseRevenue: number,
    model: ResponseModel
  ): number => {
    switch (model) {
      case 'diminishing':
        const scalingFactor = 0.4;
        const scaleFactor = Math.pow(currentCost / baseCost, scalingFactor);
        return baseRevenue * scaleFactor;

      case 'linear':
        const roas = baseRevenue / baseCost;
        return currentCost * roas;

      case 'independent':
        return convValue; // Keep current revenue

      default:
        return baseRevenue;
    }
  };

  // Update the calculateCostFromRevenue function to handle different models
  const calculateCostFromRevenue = (
    targetRevenue: number,
    baseCost: number,
    baseRevenue: number,
    model: ResponseModel
  ): number => {
    switch (model) {
      case 'diminishing':
        const scalingFactor = 0.4;
        const scaleFactor = Math.pow(targetRevenue / baseRevenue, 1 / scalingFactor);
        return baseCost * scaleFactor;

      case 'linear':
        const roas = baseRevenue / baseCost;
        return targetRevenue / roas;

      case 'independent':
        return cost; // Keep current cost

      default:
        return baseCost;
    }
  };

  // Update the handleCostChange function
  const handleCostChange = (value: number[]) => {
    const newCost = Math.max(0, value[0]);
    setCost(newCost);

    // Calculate and update revenue based on the cost change
    const newRevenue = calculateRevenueFromCost(
      newCost,
      baseMetrics.cost,
      baseMetrics.revenue,
      responseModel
    );
    setConvValue(newRevenue);
  };

  // Update the handleRevenueChange function
  const handleRevenueChange = (value: number[]) => {
    const newRevenue = Math.max(0, value[0]);
    setConvValue(newRevenue);

    // Calculate and update cost based on the revenue change
    const newCost = calculateCostFromRevenue(
      newRevenue,
      baseMetrics.cost,
      baseMetrics.revenue,
      responseModel
    );
    setCost(newCost);
  };

  // Process campaign data
  const campaigns = useMemo(() => {
    if (!thirtyDayData?.length) return [];

    const campaignMap = new Map<string, Campaign>();

    thirtyDayData.forEach((row: {
      Campaign: string;
      Cost: number;
      ConvValue: number;
      Clicks: number;
      Conversions: number;
      ImprShare?: number;
      LostToBudget?: number;
      LostToRank?: number;
      Impressions: number;
    }) => {
      if (!row?.Campaign) return;

      if (!campaignMap.has(row.Campaign)) {
        campaignMap.set(row.Campaign, {
          Campaign: row.Campaign,
          Cost: 0,
          ConvValue: 0,
          Clicks: 0,
          Conversions: 0,
          ImprShare: row.ImprShare || 0,
          LostToBudget: row.LostToBudget || 0,
          LostToRank: row.LostToRank || 0,
          Impressions: 0
        });
      }

      const campaign = campaignMap.get(row.Campaign)!;
      campaign.Cost += row.Cost || 0;
      campaign.ConvValue += row.ConvValue || 0;
      campaign.Clicks += row.Clicks || 0;
      campaign.Conversions += row.Conversions || 0;
      campaign.Impressions += row.Impressions || 0;
    });

    return Array.from(campaignMap.values())
      .filter(campaign => campaign.Cost > 0)
      .sort((a, b) => b.Cost - a.Cost);
  }, [thirtyDayData]);

  // Calculate profit projections
  const profitData = useMemo(() => {
    const selectedCampaignData = campaigns.find(c => c.Campaign === selectedCampaign);
    if (!selectedCampaignData) return [];

    return calculateProfitProjections({
      currentCost: cost,
      currentRevenue: convValue,
      conversions: selectedCampaignData.Conversions,
      cogsPercentage,
      impressionShare: selectedCampaignData.ImprShare
    });
  }, [campaigns, selectedCampaign, cost, convValue, cogsPercentage]);

  // Calculate incremental profit data
  const incrementalData = useMemo(() => {
    if (profitData.length < 2) return [];

    return profitData.slice(1).map((current, i) => {
      const prev = profitData[i];
      const incrementalCost = current.cost - prev.cost;
      const incrementalConvValue = current.convValue - prev.convValue;

      return {
        cost: current.cost,
        incrementalProfit: (incrementalConvValue * (1 - cogsPercentage / 100)) - incrementalCost,
        incrementalSales: current.sales - prev.sales,
        incrementalROAS: incrementalCost > 0 ? incrementalConvValue / incrementalCost : 0
      };
    });
  }, [profitData, cogsPercentage]);

  // Calculate current metrics
  const currentMetrics = useMemo(() => {
    const selectedCampaignData = campaigns.find(c => c.Campaign === selectedCampaign);
    if (!selectedCampaignData) return {
      revenue: 0, cost: 0, profit: 0, cpa: 0, convRate: 0, roas: 0
    };

    const revenue = convValue;
    const grossProfit = revenue * (1 - cogsPercentage / 100);
    const profit = grossProfit - cost;
    const cpa = selectedCampaignData.Conversions ? cost / selectedCampaignData.Conversions : 0;
    const convRate = selectedCampaignData.Clicks ? (selectedCampaignData.Conversions / selectedCampaignData.Clicks) * 100 : 0;
    const roas = cost > 0 ? convValue / cost : 0;

    return { revenue, cost, profit, cpa, convRate, roas };
  }, [campaigns, selectedCampaign, cost, convValue, cogsPercentage]);

  // Utility function to format numbers dynamically
  const formatNumber = (value: number): string => {
    const absValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';
    if (absValue >= 1e9) return `${sign}${(absValue / 1e9).toFixed(0)}B`;
    if (absValue >= 1e6) return `${sign}${(absValue / 1e6).toFixed(0)}M`;
    if (absValue >= 1e3) return `${sign}${(absValue / 1e3).toFixed(0)}k`;
    if (absValue < 1000) return `${sign}${absValue.toFixed(0)}`;
    return `${sign}${absValue}`;
  };

  // Chart rendering helper
  const getChartConfig = (chartType: ChartType): ExtendedChartConfig => {
    switch (chartType) {
      case 'profit-curve':
        return {
          dataKey: 'profit',
          stroke: '#8884d8',
          data: profitData,
          label: 'Profit',
          tooltipFormatter: (value) => `${selectedCurrency}${Math.round(value).toLocaleString()}`
        };
      case 'incremental-profit':
        return {
          dataKey: 'incrementalProfit',
          stroke: '#22c55e',
          data: incrementalData,
          label: 'Incremental Profit',
          tooltipFormatter: (value) => `${selectedCurrency}${Math.round(value).toLocaleString()}`
        };
      case 'profit-vs-roas':
        return {
          dataKey: 'roas',
          stroke: '#8b5cf6',
          data: profitData,
          label: 'ROAS',
          tooltipFormatter: (value) => `${value.toFixed(2)}x`
        };
      case 'marginal-roas':
        return {
          dataKey: 'marginalROAS',
          stroke: '#ef4444',
          data: profitData,
          label: 'Marginal ROAS',
          tooltipFormatter: (value) => `${value.toFixed(2)}x`
        };
    }
  };
 
  // 1. Update auto-select effect to react to data source changes
  useEffect(() => {
    if (campaigns.length > 0) {
      // Always select highest spend campaign when campaigns list changes
      const highestSpendCampaign = campaigns[0]; // campaigns are already sorted by Cost
      setSelectedCampaign(highestSpendCampaign.Campaign);
    } else {
      // Reset selection if no campaigns available
      setSelectedCampaign('');
    }
  }, [campaigns]); // campaigns will update when data source changes

  // 2. Update cost and revenue when campaign changes - updated to move together
  useEffect(() => {
    const selectedCampaignData = campaigns.find(c => c.Campaign === selectedCampaign);
    if (selectedCampaignData) {
      // Store both current values and base metrics
      const campaignCost = selectedCampaignData.Cost;
      const campaignRevenue = selectedCampaignData.ConvValue;

      // Set current values
      setCost(campaignCost);
      setConvValue(campaignRevenue);

      // Set base metrics
      setBaseMetrics({
        cost: campaignCost,
        revenue: campaignRevenue
      });

      // Update ranges
      // For cost: range from 10% to 300% of current cost
      setCostRange({
        min: campaignCost * 0.1,
        max: campaignCost * 3
      });

      // For revenue: range from 10% to 300% of current revenue
      setRevenueRange({
        min: campaignRevenue * 0.1,
        max: campaignRevenue * 3
      });
    }
  }, [selectedCampaign, campaigns]);

  // 3. Update optimal zone when profit data changes
  useEffect(() => {
    if (profitData.length > 0) {
      const newOptimalZone = findOptimalZone(profitData, cost);
      setOptimalZone(newOptimalZone);
    }
  }, [profitData, cost]);

  useEffect(() => {
    if (cogsAmount !== null) {
      setCogsPercentage(cogsAmount);
    }
  }, [cogsAmount]);

  
  // Early returns
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial' 
            ? "Loading your campaign data (this may take a minute or two)..." 
            : "Loading..."}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    );
  }

  if (!thirtyDayData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No data available.
        </div>
      </div>
    );
  }


  // render chart
  const renderActiveChart = () => {
    if (!selectedCampaign) {
      return <div className="flex items-center justify-center h-full">Select a campaign</div>;
    }
  
    const config = getChartConfig(activeChart);
    const currentProfit = currentMetrics.profit;
  
    // Find nearest cost value for reference line
    const findNearestCostValue = (data: any[], targetCost: number): number => {
      if (!data?.length) return targetCost;
      return data.reduce((nearest, point) => {
        const currentDiff = Math.abs(point.cost - targetCost);
        const nearestDiff = Math.abs(nearest - targetCost);
        return currentDiff < nearestDiff ? point.cost : nearest;
      }, data[0].cost);
    };
  
    const nearestCost = findNearestCostValue(config.data, cost);
  
    return (
      <LineChart
        data={config.data}
        margin={{ top: 20, right: 20, bottom: 5, left: 20 }}
        className="text-foreground"
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="currentColor"
          opacity={0.1}
        />
        <XAxis
          dataKey="cost"
          tickFormatter={formatNumber}
          stroke="currentColor"
          domain={['dataMin', 'dataMax']}
          padding={{ left: 20, right: 20 }}
          style={{ fontSize: 9 }}
        />
        <YAxis
          tickFormatter={formatNumber}
          stroke="currentColor"
          width={50}
          domain={['auto', 'auto']}
          allowDataOverflow={false}
          style={{ fontSize: 9 }}
        />
        <RechartsTooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <div className="p-2 bg-background border border-border rounded-lg">
                  <p>Cost: {selectedCurrency}{Math.round(label).toLocaleString()}</p>
                  <p>{config.label}: {config.tooltipFormatter(payload[0].value as number)}</p>
                </div>
              );
            }
            return null;
          }}
        />
        <ReferenceArea
          x1={optimalZone.start}
          x2={optimalZone.end}
          fill="#22c55e"
          fillOpacity={0.1}
        />
        <ReferenceLine
          x={nearestCost}
          stroke="hsl(var(--foreground))"
          strokeWidth={2}
          strokeDasharray="3 3"
          label={{
            value: "Current Cost",
            position: "top",
            fill: "hsl(var(--foreground))",
            fontSize: 12,
          }}
          isFront={true}
        />
        <ReferenceLine
          y={0}
          stroke="hsl(var(--foreground))"
          strokeWidth={2}
          strokeDasharray="3 3"
          label={{
            value: "",
            position: "bottom",
            fill: "hsl(var(--foreground))",
            fontSize: 12,
          }}
          isFront={true}
        />
        <text
          x="95%"
          y="5%"
          textAnchor="end"
          className={`font-semibold ${currentProfit > 0 ? 'fill-green-500' : 'fill-red-500'}`}
        >
          {selectedCurrency}{Math.round(currentProfit).toLocaleString()}
        </text>
        <Line
          type="monotone"
          dataKey={config.dataKey}
          stroke={config.stroke}
          dot={false}
          strokeWidth={2}
          animationDuration={300}
        />
      </LineChart>
    );
  };

  return (
    <TooltipProvider>
      <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
        <div className="flex gap-4">
          {/* Left Column - Controls */}
          <div className="w-[30%] space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Campaign Controls (uses last 30 days data)</CardTitle>
              </CardHeader>
              <CardContent className="space-y-6">
                {/* Campaign Selection */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">Campaign Selection</label>
                  <Select value={selectedCampaign} onValueChange={setSelectedCampaign}>
                    <SelectTrigger className="w-full">
                      <SelectValue>
                        {selectedCampaign ? (
                          <div className="flex items-center justify-between w-full">
                            <span className="truncate max-w-[200px]">
                              {campaigns.find(c => c.Campaign === selectedCampaign)?.Campaign}
                            </span>
                            <span className="text-sm font-medium text-muted-foreground whitespace-nowrap ml-2">
                              {selectedCurrency}{Math.round(campaigns.find(c => c.Campaign === selectedCampaign)?.Cost).toLocaleString()}
                            </span>
                          </div>
                        ) : (
                          "Select a campaign"
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {campaigns.map((campaign) => (
                        <SelectItem
                          key={campaign.Campaign}
                          value={campaign.Campaign}
                        >
                          <div className="flex items-center justify-between w-full pr-2">
                            <span className="flex-1">
                              {campaign.Campaign}
                            </span>
                            <span className="text-sm font-medium text-muted-foreground whitespace-nowrap ml-2">
                              ${Math.round(campaign.Cost).toLocaleString()}
                            </span>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Response Model */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">Response Model</label>
                  <div className="grid grid-cols-3 gap-2">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => setResponseModel('diminishing')}
                          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                            ${responseModel === 'diminishing'
                              ? 'bg-primary text-primary-foreground shadow-sm'
                              : 'bg-muted hover:bg-muted/80'}`}
                        >
                          Diminishing
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Revenue grows more slowly as spend increases</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => setResponseModel('linear')}
                          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                            ${responseModel === 'linear'
                              ? 'bg-primary text-primary-foreground shadow-sm'
                              : 'bg-muted hover:bg-muted/80'}`}
                        >
                          Linear
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Maintains current return on ad spend</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => setResponseModel('independent')}
                          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                            ${responseModel === 'independent'
                              ? 'bg-primary text-primary-foreground shadow-sm'
                              : 'bg-muted hover:bg-muted/80'}`}
                        >
                          Independent
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Cost and revenue move separately</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>

                {/* Cost Control */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">Cost</label>
                  <Slider
                    min={costRange.min}
                    max={costRange.max}
                    step={Math.max(1, (costRange.max - costRange.min) / 100)} // Dynamic step size
                    value={[Math.max(costRange.min, cost)]}
                    onValueChange={handleCostChange}
                  />
                  <p className="text-center mt-2">{selectedCurrency}{Math.round(cost).toLocaleString()}</p>
                </div>

                {/* Revenue Control */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">Revenue</label>
                  <Slider
                    min={revenueRange.min}
                    max={revenueRange.max}
                    step={Math.max(1, (revenueRange.max - revenueRange.min) / 100)} // Dynamic step size
                    value={[Math.max(revenueRange.min, convValue)]}
                    onValueChange={handleRevenueChange}
                  />
                  <p className="text-center mt-2">{selectedCurrency}{Math.round(convValue).toLocaleString()}</p>
                </div>

                {/* COGS Control - remains the same */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">COGS Percentage</label>
                  <Slider
                    min={1}
                    max={99}
                    step={1}
                    value={[Math.min(99, Math.max(1, cogsPercentage))]}
                    onValueChange={async (value) => {
                      const newValue = Math.min(99, Math.max(1, value[0]));
                      setCogsPercentage(newValue);
                      await updateCogsAmount(newValue);
                    }}
                  />
                  <p className="text-center mt-2">{cogsPercentage}%</p>
                </div>
              </CardContent>
            </Card>
          </div>
          {/* Right Column - Charts */}
          <div className="w-[70%]">
            <Card className="h-full">
              <CardHeader>
                <CardTitle>
                  {activeChart === 'profit-curve' && 'Profit Curve (optimal profit zone shown in light green)'}
                  {activeChart === 'incremental-profit' && 'Incremental Profit (optimal profit zone shown in light green)'}
                  {activeChart === 'profit-vs-roas' && 'Profit vs ROAS (optimal profit zone shown in light green)'}
                  {activeChart === 'marginal-roas' && 'Marginal ROAS (optimal profit zone shown in light green)'}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-[400px]">
                  <ResponsiveContainer width="100%" height="100%">
                    {renderActiveChart()}
                  </ResponsiveContainer>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                  <ChartThumbnail
                    title="Profit Curve"
                    isActive={activeChart === 'profit-curve'}
                    onClick={() => setActiveChart('profit-curve')}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={profitData}>
                        <Line type="monotone" dataKey="profit" stroke="#8884d8" dot={false} />
                      </LineChart>
                    </ResponsiveContainer>
                  </ChartThumbnail>
                  <ChartThumbnail
                    title="Incremental Profit"
                    isActive={activeChart === 'incremental-profit'}
                    onClick={() => setActiveChart('incremental-profit')}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={incrementalData}>
                        <Line type="monotone" dataKey="incrementalProfit" stroke="#22c55e" dot={false} />
                      </LineChart>
                    </ResponsiveContainer>
                  </ChartThumbnail>
                  <ChartThumbnail
                    title="Profit vs ROAS"
                    isActive={activeChart === 'profit-vs-roas'}
                    onClick={() => setActiveChart('profit-vs-roas')}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={profitData}>
                        <Line type="monotone" dataKey="roas" stroke="#8b5cf6" dot={false} />
                      </LineChart>
                    </ResponsiveContainer>
                  </ChartThumbnail>
                  <ChartThumbnail
                    title="Marginal ROAS"
                    isActive={activeChart === 'marginal-roas'}
                    onClick={() => setActiveChart('marginal-roas')}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={profitData}>
                        <Line type="monotone" dataKey="marginalROAS" stroke="#ef4444" dot={false} />
                      </LineChart>
                    </ResponsiveContainer>
                  </ChartThumbnail>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        {/* Advanced Profit Analysis */}
        <Card>
          <CardHeader>
            <CardTitle>Profit Analysis</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-3 gap-6">
              <div>
                <h3 className="font-semibold mb-3 text-lg">Optimal Profit Range</h3>
                <table className="w-full">
                  <tbody className="divide-y divide-border">
                    <tr>
                      <td className="py-2">
                        <div className="text-sm text-muted-foreground">Maximum Profit</div>
                        <div className="font-medium text-green-500">
                          {selectedCurrency}{Math.round(optimalZone.maxProfit).toLocaleString()}
                        </div>
                      </td>
                      <td className="py-2">
                        <div className="text-sm">Current Profit</div>
                        <div className={`font-medium ${currentMetrics.profit < 0 ? 'text-red-500' : ''}`}>
                          {selectedCurrency}{Math.round(currentMetrics.profit).toLocaleString()}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <div className="text-sm text-muted-foreground">Range Start</div>
                        <div className="font-medium">{selectedCurrency}{Math.round(optimalZone.start).toLocaleString()}</div>
                      </td>
                      <td className="py-2">
                        <div className="text-sm text-muted-foreground">Range End</div>
                        <div className="font-medium">{selectedCurrency}{Math.round(optimalZone.end).toLocaleString()}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <h3 className="font-semibold mb-3 text-lg">ROAS Analysis</h3>
                <table className="w-full">
                  <tbody className="divide-y divide-border">
                    <tr>

                      <td className="py-2">
                        <div className="text-sm text-muted-foreground">Marginal ROAS</div>
                        <div className="font-medium">
                          {(() => {
                            const currentIndex = profitData.findIndex(p => p.cost >= cost);
                            if (currentIndex <= 0 || currentIndex >= profitData.length) return "N/A";
                            const current = profitData[currentIndex];
                            const prev = profitData[currentIndex - 1];
                            const incrementalConvValue = current.convValue - prev.convValue;
                            const incrementalCost = current.cost - prev.cost;
                            return incrementalCost > 0
                              ? (incrementalConvValue / incrementalCost).toFixed(2) + 'x'
                              : "N/A";
                          })()}
                        </div>
                      </td>
                      <td className="py-2">
                        <div className="text-sm ">Current ROAS</div>
                        <div className="font-medium">{(convValue / cost).toFixed(2)}x</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">
                        <div className="text-sm text-muted-foreground">Lower Bound ROAS</div>
                        <div className="font-medium">
                          {profitData.find(p => Math.abs(p.cost - optimalZone.start) < 1)?.roas.toFixed(2)}x
                        </div>
                      </td>
                      <td className="py-2">
                        <div className="text-sm text-muted-foreground">Upper Bound ROAS</div>
                        <div className="font-medium">
                          {profitData.find(p => Math.abs(p.cost - optimalZone.end) < 1)?.roas.toFixed(2)}x
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <h3 className="font-semibold mb-3 text-lg">Current Status</h3>
                <div className="space-y-3" >
                  {cost < optimalZone.start ? (
                    <Alert variant="warning">
                      <AlertDescription className="text-lg">
                        Underspending by {selectedCurrency}{Math.round(optimalZone.start - cost).toLocaleString()}.
                        Consider increasing budget to reach optimal zone.
                      </AlertDescription>
                    </Alert>
                  ) : cost > optimalZone.end ? (
                    <Alert variant="destructive">
                      <AlertDescription className="text-lg">
                        Overspending by {selectedCurrency}{Math.round(cost - optimalZone.end).toLocaleString()}.
                        Consider reducing spend to improve profitability.
                      </AlertDescription>
                    </Alert>
                  ) : (
                    <Alert variant="success">
                      <AlertDescription className="text-lg">
                        Currently within optimal profit zone.
                        Monitor performance to maintain position.
                      </AlertDescription>
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </TooltipProvider>
  );
}