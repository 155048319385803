// src/types/metrics.ts
import { version as pkgVersion } from '../../package.json';
import { SHEET_TABS } from '@/lib/constants';
export const APP_VERSION = pkgVersion;

export type MetricType = 'Impressions' | 'Clicks' | 'Cost' | 'Conversions' | 'ConvValue';

// adding cogs & game level
export interface UserMetadata {
  webAppUrl?: string;
  lastUpdated?: string;
  cogsAmount?: number;
  currentGameLevel?: number;
}

export interface MinimalCampaign {
  Campaign: string;
  Cost: number;
}

// Base metrics that appear in most data
export interface BaseMetrics {
  Impressions: number;
  Clicks: number;
  Cost: number;
  Conversions: number;
  ConvValue: number;
  ImprShare?: number;
  LostToBudget?: number;
  LostToRank?: number;
}

// Campaign identification
export interface CampaignIdentifier {
  Campaign: string;
  CampaignId: string;
}

// Combined campaign metrics
export interface CampaignMetrics extends BaseMetrics, CampaignIdentifier { }

// Time-based data
export interface TimeData {
  Date?: string;
  Hour?: number;
}

// Daily and hourly campaign data
export interface CampaignTimeData extends CampaignMetrics, TimeData { }

// Base campaign summary that's always available
export interface BaseCampaignSummary extends BaseMetrics {
  Campaign: string;
  CampaignId: string;
}

// Extended campaign summary with optional previous period metrics
export interface CampaignSummary extends BaseCampaignSummary {
  PreviousCost?: number;
  PreviousConvValue?: number;
  PreviousConversions?: number;
}

// Campaign with required impression share metrics
export interface CampaignWithShare extends BaseCampaignSummary {
  ImprShare: number;
  LostToBudget: number;
  LostToRank: number;
}

// Full campaign interface for budget optimization
export interface OptimizableCampaign extends CampaignWithShare {
  PreviousCost?: number;
  PreviousConvValue?: number;
  PreviousConversions?: number;
}


// Specific interfaces for each data type
export interface DailyData extends CampaignTimeData {
  Date: string;
}

export interface HourlyData extends CampaignTimeData {
  Hour: number;
}

export interface ThirtyDayData extends CampaignTimeData {
  Date: string;
}

export interface PreviousThirtyDayData extends CampaignTimeData {
  Date: string;
}

export interface SevenDayData extends CampaignTimeData {
  Date: string;
}

export interface PreviousSevenDayData extends CampaignTimeData {
  Date: string;
}

export interface CampaignSettings {
  BidStrategy: string;
  BidStatus: string;
  BidType: string;
  Budget: number;
  Group: string;
  Channel: string;
  SubChannel: string;
  OptStatus: string;
  CampaignId: string;
  Labels: string;
  Campaign: string;
  TargetCPA: number | null;
  TargetROAS: number | null;
  MaxCPC: number;
  RTBOptIn: boolean;
  StatusReasons: string;
  PrimaryStatus: string;
  ServingStatus: string;
  Status: string;
  OptOutURLExp: boolean;
}

export interface ProductData extends BaseMetrics {
  ProductId: string;
  ProductTitle: string;
}

export interface SearchTermData extends BaseMetrics {
  SearchTerm: string;
}

export interface PMaxData extends CampaignTimeData {
  Date: string;
  Campaign: string;
  "Search Cost": number;
  "Search Conv": number;
  "Search Value": number;
  "Display Cost": number;
  "Display Conv": number;
  "Display Value": number;
  "Video Cost": number;
  "Video Conv": number;
  "Video Value": number;
  "Shopping Cost": number;
  "Shopping Conv": number;
  "Shopping Value": number;
  "Total Cost": number;
  "Total Conv": number;
  "Total Value": number;
  // Add any other fields that are present in your data
}

export interface FetchStatusType {
  isLoading: boolean;
  rowCount?: number;
  error?: string;
  lastUpdated?: string | null;
}

// Combined data structure for storage
export interface StorageData {
  timestamp: string;
  [SHEET_TABS.DAILY]: DailyData[];
  [SHEET_TABS.HOURLY]: HourlyData[];
  [SHEET_TABS.HOURLY_YEST]: HourlyData[];
  [SHEET_TABS.THIRTY_DAYS]: ThirtyDayData[];
  [SHEET_TABS.P_THIRTY_DAYS]: PreviousThirtyDayData[];
  [SHEET_TABS.SEVEN_DAYS]: SevenDayData[];
  [SHEET_TABS.P_SEVEN_DAYS]: PreviousSevenDayData[];
  [SHEET_TABS.SETTINGS]: CampaignSettings[];
  [SHEET_TABS.PRODUCTS]: ProductData[];
  [SHEET_TABS.SEARCH_TERMS]: SearchTermData[];
  [SHEET_TABS.PMAX_PERF]: PMaxData[];
}

// Dataset names for type safety - derive from StorageData
export type DatasetName = keyof Omit<StorageData, 'timestamp'>;