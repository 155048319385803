// src/pages/hourly.tsx
import { useState, useMemo, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCampaignData } from "@/contexts/campaign-data";
import { BaseCampaignSummary, MinimalCampaign } from '@/types/metrics';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { METRIC_CONFIGS, MetricKey, useMetricFormatter } from '@/lib/metrics';
import { useCurrency } from "@/hooks/use-currency";
import { useUser } from '@clerk/clerk-react';

// Get base metrics that are applicable for hourly view
const HOURLY_METRICS = [
  'Impressions',
  'Clicks',
  'Cost',
  'Conversions',
  'ConvValue',
  'CTR',
  'CPC',
  'CPA',
  'ROAS',
  'AOV'
] as const;

interface Campaign {
  Campaign: string;
  Cost: number;
}

interface HourlyCampaign extends BaseCampaignSummary {
  Hour: number;
}

export default function Hourly() {
  const { data, loadingStatus, error, useSampleData } = useCampaignData();
  const [selectedMetric, setSelectedMetric] = useState<MetricKey>('Cost');
  const [timeRange, setTimeRange] = useState<'today' | 'yesterday'>('today');
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const { selectedCurrency } = useCurrency()
  const formatMetric = useMetricFormatter();
  const { isSignedIn } = useUser();

  // Get current dataset based on timeRange
  const currentData = useMemo(() => {
    return timeRange === 'today' ? data?.hourly || [] : data?.hourly_yest || [];
  }, [timeRange, data]);

  // Process campaigns following the guide's pattern
  const campaigns = useMemo(() => {
    if (!currentData?.length) return [] as MinimalCampaign[];

    const campaignMap = new Map<string, MinimalCampaign>();

    currentData.forEach(row => {
      if (!row?.Campaign) return;

      if (!campaignMap.has(row.Campaign)) {
        campaignMap.set(row.Campaign, {
          Campaign: row.Campaign,
          Cost: 0
        });
      }

      const campaign = campaignMap.get(row.Campaign)!;
      campaign.Cost += row.Cost || 0;
    });

    return Array.from(campaignMap.values())
      .filter(campaign => campaign.Cost > 0)
      .sort((a, b) => b.Cost - a.Cost);
  }, [currentData]);


  // Auto-select highest cost campaign
  useEffect(() => {
    if (campaigns.length > 0) {
      // Always select highest spend campaign when campaigns list changes
      const highestSpendCampaign = campaigns[0]; // campaigns are already sorted by Cost
      setSelectedCampaign(highestSpendCampaign.Campaign);
    } else {
      // Reset selection if no campaigns available
      setSelectedCampaign('');
    }
  }, [campaigns]); // campaigns will update when data source changes

  
  // Aggregate data by hour with calculated metrics
  const aggregatedData = useMemo(() => {
    if (!selectedCampaign) return [];

    const filtered = currentData.filter(row =>
      row.Campaign === selectedCampaign
    );

    return Array(24).fill(0).map((_, hour) => {
      const hourData = filtered.find(row => row.Hour === hour) || {
        Hour: hour,
        Impressions: 0,
        Clicks: 0,
        Cost: 0,
        Conversions: 0,
        ConvValue: 0
      };

      // Calculate derived metrics for the hour
      const metrics = {
        hour,
        ...hourData,
        CTR: hourData.Impressions ? (hourData.Clicks / hourData.Impressions) * 100 : 0,
        CPC: hourData.Clicks ? hourData.Cost / hourData.Clicks : 0,
        CPA: hourData.Conversions ? hourData.Cost / hourData.Conversions : 0,
        ROAS: hourData.Cost ? hourData.ConvValue / hourData.Cost : 0,
        AOV: hourData.Conversions ? hourData.ConvValue / hourData.Conversions : 0,
      };

      return metrics;
    });
  }, [currentData, selectedCampaign]);


  // early returns
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial' 
            ? "Loading your campaign data (this may take a minute or two)..." 
            : "Loading..."}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    );
  }

  if (!currentData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No hourly data available.
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <Select value={selectedCampaign} onValueChange={setSelectedCampaign}>
          <SelectTrigger className="w-[300px] md:w-[400px] lg:w-[500px]">
            <SelectValue>
              {selectedCampaign ? (
                <div className="flex items-center justify-between w-full">
                  <span className="truncate max-w-[200px]">
                    {campaigns.find(c => c.Campaign === selectedCampaign)?.Campaign}
                  </span>
                  <span className="text-sm font-medium text-muted-foreground ml-2">
                    {selectedCurrency}{campaigns.find(c => c.Campaign === selectedCampaign)?.Cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </span>
                </div>
              ) : (
                "Select a campaign"
              )}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {campaigns.map((campaign) => (
              <SelectItem key={campaign.Campaign} value={campaign.Campaign}>
                <div className="flex items-center justify-between w-full">
                  <span className="truncate">{campaign.Campaign}</span>
                  <span className="text-sm text-muted-foreground ml-2">
                    {selectedCurrency}{campaign.Cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </span>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex gap-4 items-center">
          <Select value={selectedMetric} onValueChange={(v) => setSelectedMetric(v as MetricKey)}>
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Select metric" />
            </SelectTrigger>
            <SelectContent>
              {HOURLY_METRICS.map((key) => (
                <SelectItem key={key} value={key}>
                  {METRIC_CONFIGS[key].label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Tabs value={timeRange} onValueChange={(v) => setTimeRange(v as 'today' | 'yesterday')}>
            <TabsList>
              <TabsTrigger value="today">Today</TabsTrigger>
              <TabsTrigger value="yesterday">Yesterday</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>
            {useSampleData && '(Sample) '}
            Hourly {METRIC_CONFIGS[selectedMetric].label}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={aggregatedData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="hour"
                  tickFormatter={(hour) => `${hour}:00`}
                />
                <YAxis
                  tickFormatter={(value) => formatMetric(selectedMetric, value)}
                />
                <Tooltip
                  formatter={(value: number) => [
                    formatMetric(selectedMetric, value),
                    METRIC_CONFIGS[selectedMetric].label
                  ]}
                  labelFormatter={(hour) => `${hour}:00`}
                />
                <Bar
                  dataKey={selectedMetric}
                  fill={useSampleData ? "#94a3b8" : METRIC_CONFIGS[selectedMetric].color || "#2563eb"}
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}