import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Settings, TrendingUp, LineChart, Brain, Moon, Clock, ChevronRight, LogIn } from "lucide-react"
import { FeedbackForm } from "@/components/feedback-form"
import { cn } from "@/lib/utils"
import { Link } from "react-router-dom"

interface PageSection {
  title: string
  content: string
  icon: React.ComponentType<{ className?: string }>
  href: string
}

export default function HelpPage() {
  const sections: PageSection[] = [
    {
      title: "Login Benefits",
      content: "Logging in allows the app to remember your preferences, including your deployed Web App URL, COGS value, and script challenge progress.",
      icon: LogIn,
      href: "/"
    },
    {
      title: "Settings",
      content: "The settings page lets you manually trigger data updates and set your preferred currency.",
      icon: Settings,
      href: "/settings"
    },
    {
      title: "Analysis",
      content: "Our main page provides budget optimization suggestions for every campaign. View Performance Max channel metrics.",
      icon: TrendingUp,
      href: "/analysis"
    },
    {
      title: "Trends",
      content: "Visualize and track various metrics including Performance Max channel data over your selected time period.",
      icon: TrendingUp,
      href: "/trends"
    },
    {
      title: "Profit Curve",
      content: "Experiment with profit curves for individual campaigns. Data is automatically imported for quick campaign comparison.",
      icon: LineChart,
      href: "/curve"
    },
    {
      title: "Hourly Metrics",
      content: "Monitor today's and yesterday's hourly performance metrics. Track real-time changes and compare performance.",
      icon: Clock,
      href: "/hourly"
    },
    {
      title: "Script Challenge",
      content: "Complete our 9-level challenge in about 14 minutes to become more comfortable with scripts.",
      icon: Brain,
      href: "/scary"
    }
  ]

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-secondary/10">
      <div className="container px-4 py-12">
        <div className="mb-12 text-center">
          <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl">Help Center</h1>
          <p className="mt-4 text-muted-foreground">Everything you need to know about using our platform</p>
        </div>

        <Tabs defaultValue="guide">
          <div className="flex justify-center mb-8">
            <TabsList className="inline-flex bg-secondary/40">
              <TabsTrigger 
                value="guide" 
                className="px-8 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground hover:bg-primary/80"
              >
                Quick Start Guide
              </TabsTrigger>
              <TabsTrigger 
                value="feedback" 
                className="px-8 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground hover:bg-primary/80"
              >
                Feedback
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value="guide">
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {sections.map((section) => (
                <Link
                  key={section.title}
                  to={section.href}
                  className={cn(
                    "group relative overflow-hidden rounded-lg border bg-background p-6 transition-all hover:shadow-lg hover:-translate-y-1",
                    "before:absolute before:inset-0 before:bg-gradient-to-r before:from-primary/10 before:to-secondary/10 before:opacity-0 before:transition-opacity hover:before:opacity-100"
                  )}
                >
                  <div className="relative z-10">
                    <div className="mb-4 flex items-center justify-between">
                      <section.icon className="h-8 w-8 text-primary" />
                      <ChevronRight className="h-5 w-5 text-muted-foreground transition-transform group-hover:translate-x-1" />
                    </div>
                    <h2 className="mb-2 text-xl font-semibold tracking-tight">{section.title}</h2>
                    <p className="text-sm text-muted-foreground">{section.content}</p>
                  </div>
                </Link>
              ))}
            </div>
          </TabsContent>

          <TabsContent value="feedback" className="mt-6">
            <FeedbackForm />
          </TabsContent>
        </Tabs>

        <div className="mt-12 flex justify-center">
          <div className="rounded-lg border bg-card px-6 py-4 text-card-foreground shadow-sm">
            <p className="text-sm text-muted-foreground">
              Need more help?{" "}
              <a href="https://mikerhodes.circle.so/c/help/" className="font-medium text-primary hover:underline">
                Contact Support
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}