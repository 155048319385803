// src/components/budget-optimization/budget-optimization.tsx

import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BudgetSlider } from "./budget-slider";
import { useCampaignProjections } from "./use-campaign-projections";
import { CampaignProjection, OptimizationMode, ProjectionFilter } from "./types";
import { Campaign } from '@/lib/profit-utils';
import { useCurrency } from '@/hooks/use-currency';
import type { OptimizableCampaign } from '@/types/metrics';

interface BudgetOptimizationProps {
    campaigns: OptimizableCampaign[];
    cogsPercentage: number;
    includeFilter: string;
    excludeFilter: string;
    rowLimit: number;
    optimizationMode: OptimizationMode;
}

interface MetricDisplayProps {
    label: string;
    value: number;
    previousValue: number;
    formatter?: (n: number) => string;
    inverse?: boolean;
    isPercentage?: boolean;
    prependValue?: boolean;
}

const handleTabClick = (event: React.MouseEvent) => {
    event.preventDefault();
};

function MetricDisplay({
    label,
    value,
    previousValue,
    formatter = (n) => Math.round(n).toLocaleString(),
    inverse = false,
}: MetricDisplayProps) {
    // Handle NaN values
    const safeValue = isNaN(value) ? 0 : value;
    const safePreviousValue = isNaN(previousValue) ? 0 : previousValue;

    const percentChange = safePreviousValue ?
        ((safeValue - safePreviousValue) / Math.abs(safePreviousValue)) * 100 : 0;
    const formattedValue = formatter(safeValue);

    return (
        <div className="text-center">
            <p className="text-sm font-medium">{label}</p>
            <div className="mt-1">
                <p className="text-xl font-bold">{formattedValue}</p>
                {percentChange !== 0 && !isNaN(percentChange) && (
                    <p className={`text-sm ${percentChange === 0 ? 'text-muted-foreground' :
                        percentChange > 0 === !inverse ? 'text-green-500' : 'text-red-500'
                        }`}>
                        {percentChange > 0 ? '↑' : '↓'} {Math.abs(Math.round(percentChange))}%
                    </p>
                )}
            </div>
        </div>
    );
}

export function BudgetOptimization({
    campaigns,
    cogsPercentage,
    includeFilter,
    excludeFilter,
    rowLimit,
    optimizationMode,
}: BudgetOptimizationProps) {
    const { selectedCurrency } = useCurrency();
    const [projectionFilter, setProjectionFilter] = useState<ProjectionFilter>('all');

    const {
        campaignProjections,
        projectedMetrics,
        rowAdjustments,
        setRowAdjustments
    } = useCampaignProjections(
        campaigns,
        cogsPercentage,
        optimizationMode,
        includeFilter,
        excludeFilter
    );

    // Filter projections based on user selection
    const filteredProjections = campaignProjections.filter(projection => {
        const currentAdjustment = rowAdjustments[projection.name]?.adjustment ?? 0;

        if (projection.isHighIS) {
            return projectionFilter === 'high-is' || projectionFilter === 'all';
        }

        switch (projectionFilter) {
            case 'increase':
                return currentAdjustment > 0;
            case 'decrease':
                return currentAdjustment < 0;
            case 'nochange':
                return currentAdjustment === 0 && !projection.isHighIS;
            case 'high-is':
                return projection.isHighIS;
            default:
                return true;
        }
    }).slice(0, rowLimit);

    const getStatusDisplay = (projection: CampaignProjection) => {
        if (projection.isHighIS) {
          return (
            <span className="text-blue-600">
              No Change (High IS)
            </span>
          );
        }
      
        return <span className={
          projection.recommendation === 'Increase Cost' ? 'text-green-600' :
          projection.recommendation === 'Decrease Cost' ? 'text-red-600' :
          projection.recommendation === 'Pause Campaign' ? 'text-foreground' :
          'text-blue-600'
        }>
          {projection.recommendation}
        </span>;
      };

    return (
        <div className="space-y-6">
            {/* Projected Performance Impact */}
            <Card className="mb-8">
                <CardHeader>
                    <CardTitle>Projected Performance Impact for Filtered Campaigns</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-8 gap-3">
                        <MetricDisplay
                            label="Total Cost"
                            value={projectedMetrics.projected.cost}
                            previousValue={projectedMetrics.current.cost}
                            formatter={(n) => `${selectedCurrency}${Math.round(n).toLocaleString()}`}
                            inverse={true}
                        />
                        <MetricDisplay
                            label="Total Revenue"
                            value={projectedMetrics.projected.revenue}
                            previousValue={projectedMetrics.current.revenue}
                            formatter={(n) => `${selectedCurrency}${Math.round(n).toLocaleString()}`}
                        />
                        <MetricDisplay
                            label="Total Profit"
                            value={projectedMetrics.projected.profit}
                            previousValue={projectedMetrics.current.profit}
                            formatter={(n) => `${selectedCurrency}${Math.round(n).toLocaleString()}`}
                        />
                        <MetricDisplay
                            label="Avg CPA"
                            value={projectedMetrics.projected.conversions > 0
                                ? projectedMetrics.projected.cost / projectedMetrics.projected.conversions
                                : 0}
                            previousValue={projectedMetrics.current.conversions > 0
                                ? projectedMetrics.current.cost / projectedMetrics.current.conversions
                                : 0}
                            formatter={(n) => n === 0 ? '-' : `${selectedCurrency}${n.toFixed(2)}`}
                            inverse={true}
                        />
                        <MetricDisplay
                            label="Conv Rate"
                            value={projectedMetrics.projected.clicks > 0
                                ? (projectedMetrics.projected.conversions / projectedMetrics.projected.clicks * 100)
                                : 0}
                            previousValue={projectedMetrics.current.clicks > 0
                                ? (projectedMetrics.current.conversions / projectedMetrics.current.clicks * 100)
                                : 0}
                            formatter={(n) => `${n.toFixed(1)}%`}
                            isPercentage={false}
                        />
                        <MetricDisplay
                            label="ROAS"
                            value={projectedMetrics.projected.cost > 0
                                ? projectedMetrics.projected.revenue / projectedMetrics.projected.cost
                                : 0}
                            previousValue={projectedMetrics.current.cost > 0
                                ? projectedMetrics.current.revenue / projectedMetrics.current.cost
                                : 0}
                            formatter={(n) => n === 0 ? '-' : `${n.toFixed(1)}x`}
                        />
                        <MetricDisplay
                            label={`Rev/Extra ${selectedCurrency}`}
                            value={(projectedMetrics.projected.revenue - projectedMetrics.current.revenue) /
                                (projectedMetrics.projected.cost - projectedMetrics.current.cost)}
                            previousValue={0}
                            formatter={(n) => {
                                if (!isFinite(n) || isNaN(n)) return '-'
                                return `${selectedCurrency}${n.toFixed(1)}`
                            }}
                            prependValue={false}
                        />
                        <MetricDisplay
                            label="Total Conversions"
                            value={projectedMetrics.projected.conversions}
                            previousValue={projectedMetrics.current.conversions}
                            formatter={(n) => n === 0 ? '-' : Math.round(n).toLocaleString()}
                            prependValue={false}
                        />
                    </div>
                </CardContent>
            </Card>

            {/* Budget Change Projections */}
            <Card>
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Budget Change Projections</CardTitle>
                        <Tabs
                            value={projectionFilter}
                            onValueChange={(value) => setProjectionFilter(value as ProjectionFilter)}
                        >
                            <TabsList>
                                <TabsTrigger value="all" onClick={handleTabClick} className="focus:outline-none">All Changes</TabsTrigger>
                                <TabsTrigger value="increase" onClick={handleTabClick} className="focus:outline-none">Increases</TabsTrigger>
                                <TabsTrigger value="decrease" onClick={handleTabClick} className="focus:outline-none">Decreases</TabsTrigger>
                                <TabsTrigger value="nochange" onClick={handleTabClick} className="focus:outline-none">No Change</TabsTrigger>
                                <TabsTrigger value="high-is" onClick={handleTabClick} className="focus:outline-none">High IS</TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="min-h-[200px]">
                        {filteredProjections.length > 0 ? (
                            <table className="w-full text-sm text-left">
                                <thead>
                                    <tr className="border-b">
                                        <th className="px-4 py-2">Campaign</th>
                                        <th className="px-4 py-2">Projected Cost</th>
                                        <th className="px-4 py-2">Projected Profit</th>
                                        <th className="px-4 py-2">Action</th>
                                        <th className="px-4 py-2">Reason for Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredProjections.map(projection => (
                                        <tr key={projection.name} className="border-b">
                                            <td className="px-4 py-2">
                                                {projection.name}
                                                {projection.isHighIS && (
                                                    <span className="ml-2 text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800">
                                                        {Math.round(projection.currentIS)}% IS
                                                    </span>
                                                )}
                                            </td>
                                            <td className="px-4 py-2">
                                                <BudgetSlider
                                                    projection={projection}
                                                    value={rowAdjustments[projection.name]?.adjustment ?? 0}
                                                    onChange={(value) => {
                                                        setRowAdjustments(prev => ({
                                                            ...prev,
                                                            [projection.name]: {
                                                                campaignName: projection.name,
                                                                adjustment: value
                                                            }
                                                        }));
                                                    }}
                                                    disabled={projection.isHighIS ||
                                                        (projection.recommendation === 'No Change' && optimizationMode !== 'aggressive')}
                                                />
                                            </td>
                                            <td className="px-4 py-2">
                                                <span className={projection.projectedProfit < 0 ? 'text-red-500' : ''}>
                                                    {selectedCurrency}{Math.round(projection.projectedProfit).toLocaleString()}
                                                    <span className="text-muted-foreground ml-2">
                                                        ({projection.profitChange > 0 ? '+' : ''}
                                                        {selectedCurrency}{Math.round(projection.profitChange).toLocaleString()})
                                                    </span>
                                                </span>
                                            </td>
                                            <td className="px-4 py-2">
                                                {getStatusDisplay(projection)}
                                            </td>
                                            <td className="px-4 py-2">{projection.changeReason}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="flex items-center justify-center h-[200px] text-muted-foreground">
                                No campaigns found for this filter
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}