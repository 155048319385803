// src/lib/sample-data-utils.ts
import Papa from 'papaparse';
import { 
  StorageData, 
  DatasetName,
  DailyData,
  HourlyData,
  CampaignSettings,
  ProductData,
  SearchTermData,
  PMaxData
} from '../types/metrics';
import { STORAGE_KEYS, SHEET_TABS } from './constants';

// Loading state tracker
let isLoading = false;
let loadingPromise: Promise<StorageData> | null = null;

// Helper function to get the correct base URL for sample data
const getSampleDataUrl = (filename: string) => {
  const isDev = import.meta.env.MODE === 'development';
  const isReplit = window.location.hostname.includes('replit');

  return `/sample-data/${filename}`; // use same for dev & prod
};

// Base metric transformation
const transformBaseMetrics = (row: Record<string, any>) => ({
  Impressions: Number(row.impressions || row.Impressions || 0),
  Clicks: Number(row.clicks || row.Clicks || 0),
  Cost: Number(row.cost || row.Cost || 0),
  Conversions: Number(row.conversions || row.Conversions || 0),
  ConvValue: Number(row.convValue || row.ConvValue || 0),
  ImprShare: Number(row.ImprShare || row.imprShare || 0),
  LostToBudget: Number(row.LostToBudget || row.lostToBudget || 0),
  LostToRank: Number(row.LostToRank || row.lostToRank || 0)
});

// Campaign data transformation
const transformCampaignData = (row: Record<string, any>) => ({
  ...transformBaseMetrics(row),
  Campaign: String(row.campaign || row.Campaign || ''),
  CampaignId: String(row.campaignId || row.CampaignId || ''),
  Date: row.date || row.Date || ''
});

type TransformerMap = {
  [K in DatasetName]: (data: any[]) => StorageData[K]
};

export const transformers: TransformerMap = {
  [SHEET_TABS.DAILY]: (data: any[]): DailyData[] => 
    data.map(row => ({
      ...transformCampaignData(row),
      Date: String(row.date || row.Date || '') 
    })),

  [SHEET_TABS.THIRTY_DAYS]: (data: any[]): DailyData[] => 
    data.map(row => ({
      ...transformCampaignData(row),
      Date: String(row.date || row.Date || '')
    })),

  [SHEET_TABS.P_THIRTY_DAYS]: (data: any[]): DailyData[] => 
    data.map(row => ({
      ...transformCampaignData(row),
      Date: String(row.date || row.Date || '')
    })),

  [SHEET_TABS.SEVEN_DAYS]: (data: any[]): DailyData[] => 
    data.map(row => ({
      ...transformCampaignData(row),
      Date: String(row.date || row.Date || '')
    })),

  [SHEET_TABS.P_SEVEN_DAYS]: (data: any[]): DailyData[] => 
    data.map(row => ({
      ...transformCampaignData(row),
      Date: String(row.date || row.Date || '')
    })),

  [SHEET_TABS.HOURLY]: (data: any[]): HourlyData[] =>
    data.map(row => ({
      ...transformCampaignData(row),
      Hour: Number(row.hour || row.Hour || 0)
    })),

  [SHEET_TABS.HOURLY_YEST]: (data: any[]): HourlyData[] =>
    data.map(row => ({
      ...transformCampaignData(row),
      Hour: Number(row.hour || row.Hour || 0)
    })),

  [SHEET_TABS.SETTINGS]: (data: any[]): CampaignSettings[] =>
    data.map(row => ({
      BidStrategy: String(row.BidStrategy || ''),
      BidStatus: String(row.BidStatus || ''),
      BidType: String(row.BidType || ''),
      Budget: Number(row.Budget || 0),
      Group: String(row.Group || ''),
      Channel: String(row.Channel || ''),
      SubChannel: String(row.SubChannel || ''),
      OptStatus: String(row.OptStatus || ''),
      CampaignId: String(row.CampaignId || ''),
      Labels: String(row.Labels || ''),
      Campaign: String(row.Campaign || ''),
      TargetCPA: row.TargetCPA !== undefined ? Number(row.TargetCPA) : null,
      TargetROAS: row.TargetROAS !== undefined ? Number(row.TargetROAS) : null,
      MaxCPC: Number(row.MaxCPC || 0),
      RTBOptIn: Boolean(row.RTBOptIn),
      StatusReasons: String(row.StatusReasons || ''),
      PrimaryStatus: String(row.PrimaryStatus || ''),
      ServingStatus: String(row.ServingStatus || ''),
      Status: String(row.Status || ''),
      OptOutURLExp: Boolean(row.OptOutURLExp)
    })),

  [SHEET_TABS.PRODUCTS]: (data: any[]): ProductData[] =>
    data.map(row => ({
      ...transformBaseMetrics(row),
      ProductId: String(row.ProductId || ''),
      ProductTitle: String(row.ProductTitle || '')
    })),

  [SHEET_TABS.SEARCH_TERMS]: (data: any[]): SearchTermData[] =>
    data.map(row => ({
      ...transformBaseMetrics(row),
      SearchTerm: String(row.SearchTerm || '')
    })),

  [SHEET_TABS.PMAX_PERF]: (data: any[]): PMaxData[] =>
    data.map((row) => ({
      ...transformCampaignData(row),
      Date: row.Date,
      Campaign: row.Campaign,
      "Search Cost": parseFloat(row["Search Cost"]) || 0,
      "Search Conv": parseFloat(row["Search Conv"]) || 0,
      "Search Value": parseFloat(row["Search Value"]) || 0,
      "Display Cost": parseFloat(row["Display Cost"]) || 0,
      "Display Conv": parseFloat(row["Display Conv"]) || 0,
      "Display Value": parseFloat(row["Display Value"]) || 0,
      "Video Cost": parseFloat(row["Video Cost"]) || 0,
      "Video Conv": parseFloat(row["Video Conv"]) || 0,
      "Video Value": parseFloat(row["Video Value"]) || 0,
      "Shopping Cost": parseFloat(row["Shopping Cost"]) || 0,
      "Shopping Conv": parseFloat(row["Shopping Conv"]) || 0,
      "Shopping Value": parseFloat(row["Shopping Value"]) || 0,
      "Total Cost": parseFloat(row["Total Cost"]) || 0,
      "Total Conv": parseFloat(row["Total Conv"]) || 0,
      "Total Value": parseFloat(row["Total Value"]) || 0,
    })),
    
} as const;

export async function initializeWithSampleData(saveToStorage = false): Promise<StorageData> {
  // If already loading, return the existing promise
  if (isLoading && loadingPromise) {
    return loadingPromise;
  }

  // Set loading state and create new promise
  isLoading = true;
  loadingPromise = (async () => {
    try {
      // Clear any existing localStorage data
      if (saveToStorage) {
        localStorage.removeItem(STORAGE_KEYS.CAMPAIGN_DATA);
        localStorage.removeItem(STORAGE_KEYS.GAME_LEVEL);
      }

      const sampleData = {
        timestamp: new Date().toISOString(),
        daily: [],
        thirty_days: [],
        p_thirty_days: [],
        seven_days: [],
        p_seven_days: [],
        hourly: [],
        hourly_yest: [],
        settings: [],
        products: [],
        search_terms: [],
        pmax_perf: []
      };

      const tabPromises = (Object.keys(SHEET_TABS) as (keyof typeof SHEET_TABS)[]).map(async (key) => {
        const tabValue = SHEET_TABS[key].toLowerCase();
        try {
          const url = getSampleDataUrl(`sample_${tabValue}.csv`);
          console.log(`Fetching sample data from: ${url}`);
          
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const text = await response.text();

          const result = Papa.parse(text, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true
          });

          if (result.data && Array.isArray(result.data)) {
            const transformer = transformers[tabValue as DatasetName];
            if (transformer) {
              const transformedData = transformer(result.data);
              (sampleData[tabValue as DatasetName] as any[]) = transformedData;
            }
          }
        } catch (error) {
          console.error(`Error loading sample ${tabValue}:`, error);
          // If we fail to load sample data, keep the empty array as initialized
          (sampleData[tabValue as DatasetName] as any[]) = [];
        }
      });

      // Wait for all fetches to complete
      await Promise.all(tabPromises);

      if (saveToStorage) {
        localStorage.setItem(STORAGE_KEYS.CAMPAIGN_DATA, JSON.stringify(sampleData));
      }

      return sampleData;
    } finally {
      isLoading = false;
      loadingPromise = null;
    }
  })();

  return loadingPromise;
}