import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { useUser } from "@clerk/clerk-react"
import { useCampaignData } from "@/contexts/campaign-data"
import { ArrowRight, BarChart3, Sparkles, Target, Trophy, Rocket, ChevronRight, Clock, Zap, Save, Brain } from "lucide-react"
import { cn } from "@/lib/utils"

interface FeatureCard {
  title: string
  description: string
  icon: React.ComponentType<{ className?: string }>
  href?: string // Optional link for actionable cards
}

export default function Index() {
  const navigate = useNavigate()
  const { isSignedIn } = useUser()
  const { currentGameLevel = 0 } = useCampaignData()

  const features: FeatureCard[] = [
    {
      title: "Smart Budget Allocation",
      description: "AI-driven suggestions to optimize your campaign budgets for maximum ROI",
      icon: Sparkles
    },
    {
      title: "Performance Tracking",
      description: "Track Performance Max metrics across all channels in real-time",
      icon: Target
    },
    {
      title: "Profit Optimization",
      description: "Visualize and optimize your profit curves for each campaign",
      icon: Trophy
    },
    {
      title: "Instant Setup",
      description: "Get started immediately with a free account. No credit card required.",
      icon: Zap
    },
    {
      title: "Remember Settings",
      description: "Your Web App URL, COGS value, and preferences are saved automatically",
      icon: Save
    },
    {
      title: isSignedIn && currentGameLevel > 0 ? "Don't stop now!" : "Take the Script Challenge",
      description: isSignedIn && currentGameLevel > 0 
        ? `Keep going. You're doing great! Continue on from Level ${currentGameLevel + 1} & get to Mastery.`
        : "Take our 14 minute script challenge to boost your confidence with automation",
      icon: Brain,
      href: '/scary'
    }
  ]

  return (
    <div className="relative bg-gradient-to-b from-background to-secondary/10">
      <div className="container px-4 py-16">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-bold tracking-tight mb-4 bg-gradient-to-r from-primary to-primary-foreground bg-clip-text text-transparent">
            The Budget Maximiser
          </h1>
          <p className="text-xl text-muted-foreground">
            Maximize your profits, not the platform's
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12">
          {features.map((feature) => (
            <div
              key={feature.title}
              onClick={() => feature.href && navigate(feature.href)}
              className={cn(
                "group relative overflow-hidden rounded-lg border bg-background/60 backdrop-blur-sm p-6",
                "before:absolute before:inset-0 before:bg-gradient-to-r before:from-primary/5 before:to-secondary/5",
                feature.href && "cursor-pointer hover:shadow-lg hover:-translate-y-1 transition-all",
                "before:opacity-0 before:transition-opacity hover:before:opacity-100",
                feature.href === '/scary' && "bg-orange-500/10 dark:bg-orange-500/20 border-orange-500/20"
              )}
            >
              <div className="relative z-10">
                <div className="mb-4 flex items-center justify-between">
                  <feature.icon className="h-8 w-8 text-primary" />
                  {feature.href && (
                    <ChevronRight className="h-5 w-5 text-muted-foreground transition-transform group-hover:translate-x-1" />
                  )}
                </div>
                <h2 className="mb-2 text-xl font-semibold tracking-tight">{feature.title}</h2>
                <p className="text-sm text-muted-foreground">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="max-w-xl mx-auto">
          {isSignedIn ? (
            <div className="space-y-6 flex flex-col items-center text-center"> {/* Added flex and items-center */}
              <h2 className="text-2xl font-semibold">Ready to optimize your campaigns?</h2>
              <Button
                size="lg"
                onClick={() => navigate('/settings')}
                className="flex items-center justify-center gap-2 py-6"
              >
                <Rocket className="h-5 w-5" />
                Configure Integration
                <ChevronRight className="h-5 w-5" />
              </Button>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Button
                  size="lg"
                  onClick={() => navigate('/sign-up')}
                  className="flex items-center justify-center gap-2 py-6"
                >
                  <Rocket className="h-5 w-5" />
                  It's Free - Sign Up!
                  <ChevronRight className="h-5 w-5" />
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => navigate('/analysis')}
                  className="flex items-center justify-center gap-2"
                >
                  Try Sample Data
                  <BarChart3 className="h-5 w-5" />
                </Button>
              </div>

              <div className="text-center">
                <p className="text-sm text-muted-foreground flex items-center justify-center gap-2">
                  Already have an account?{" "}
                  <Button
                    variant="link"
                    className="p-0 h-auto"
                    onClick={() => navigate('/sign-in')}
                  >
                    Sign in
                  </Button>
                  <span className="text-muted-foreground">or</span>
                  <Button
                    variant="link"
                    className="p-0 h-auto"
                    onClick={() => navigate('/sign-up')}
                  >
                    Sign up
                  </Button>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}