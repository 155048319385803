// src/pages/analysis.tsx
"use client"

import { useState, useMemo, useEffect } from 'react'
import { SHEET_TABS } from '@/lib/constants'
import type { OptimizableCampaign, StorageData, ThirtyDayData, SevenDayData, PreviousThirtyDayData, PreviousSevenDayData, BaseMetrics } from '@/types/metrics'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { Slider } from "@/components/ui/slider"
import { HelpCircle } from "lucide-react"
import { useCampaignData } from "@/contexts/campaign-data"
import { BudgetOptimization } from '@/components/budget-optimization/budget-optimization'
import type { OptimizationMode } from '@/components/budget-optimization/types'
import { useCurrency } from "@/hooks/use-currency"
import { useUser } from '@clerk/clerk-react';

interface CampaignSummary extends BaseMetrics {
  Campaign: string;
  PreviousCost: number;
  PreviousConvValue: number;
  PreviousConversions: number;
}

export default function Analysis() {
  const { data, loadingStatus, error, cogsAmount, updateCogsAmount } = useCampaignData()
  const { selectedCurrency } = useCurrency();
  const { isSignedIn } = useUser();

  // State management
  const [selectedPeriod, setSelectedPeriod] = useState<'30d' | '7d'>('30d')
  const [cogsPercentage, setCogsPercentage] = useState(cogsAmount ?? 50)
  const [includeFilter, setIncludeFilter] = useState('')
  const [excludeFilter, setExcludeFilter] = useState('')
  const [rowLimit, setRowLimit] = useState(10)
  const [optimizationMode, setOptimizationMode] = useState<OptimizationMode>('none')
  const [selectedTab, setSelectedTab] = useState('projections')

  // Extract data arrays with fallbacks
  const dataArrays = useMemo(() => ({
    thirtyDayData: data?.[SHEET_TABS.THIRTY_DAYS] ?? [],
    previousThirtyDays: data?.[SHEET_TABS.P_THIRTY_DAYS] ?? [],
    sevenDayData: data?.[SHEET_TABS.SEVEN_DAYS] ?? [],
    previousSevenDays: data?.[SHEET_TABS.P_SEVEN_DAYS] ?? []
  }), [data]) as {
    thirtyDayData: ThirtyDayData[];
    previousThirtyDays: PreviousThirtyDayData[];
    sevenDayData: SevenDayData[];
    previousSevenDays: PreviousSevenDayData[];
  }

  // Calculate campaigns with proper typing
  const campaigns = useMemo(() => {
    if (!data) return [] as OptimizableCampaign[]

    const campaignMap = new Map<string, OptimizableCampaign>();
    const currentData = selectedPeriod === '30d' ? dataArrays.thirtyDayData : dataArrays.sevenDayData
    const previousData = selectedPeriod === '30d' ? dataArrays.previousThirtyDays : dataArrays.previousSevenDays

    // Process current data
    currentData.forEach(row => {
      if (!row?.Campaign) return

      if (!campaignMap.has(row.Campaign)) {
        campaignMap.set(row.Campaign, {
          Campaign: row.Campaign,
          CampaignId: row.CampaignId,
          Cost: row.Cost || 0,
          ConvValue: row.ConvValue || 0,
          Clicks: row.Clicks || 0,
          Conversions: row.Conversions || 0,
          ImprShare: row.ImprShare || 0,
          LostToBudget: row.LostToBudget || 0,
          LostToRank: row.LostToRank || 0,
          Impressions: row.Impressions || 0,
          PreviousCost: 0,
          PreviousConvValue: 0,
          PreviousConversions: 0
        })
      } else {
        const campaign = campaignMap.get(row.Campaign)!
        campaign.Cost += row.Cost || 0
        campaign.ConvValue += row.ConvValue || 0
        campaign.Clicks += row.Clicks || 0
        campaign.Conversions += row.Conversions || 0
        campaign.Impressions += row.Impressions || 0
        campaign.ImprShare = row.ImprShare || campaign.ImprShare
        campaign.LostToBudget = row.LostToBudget || campaign.LostToBudget
        campaign.LostToRank = row.LostToRank || campaign.LostToRank
      }
    })

    // Process previous data
    previousData.forEach(row => {
      if (!row?.Campaign || !campaignMap.has(row.Campaign)) return
      const campaign = campaignMap.get(row.Campaign)!
      campaign.PreviousCost = row.Cost || 0
      campaign.PreviousConvValue = row.ConvValue || 0
      campaign.PreviousConversions = row.Conversions || 0
    })

    return Array.from(campaignMap.values())
  }, [selectedPeriod, dataArrays])

  // Performance metrics calculation
  const performanceMetrics = useMemo(() => {
    const currentData = selectedPeriod === '30d' ? dataArrays.thirtyDayData : dataArrays.sevenDayData
    const previousData = selectedPeriod === '30d' ? dataArrays.previousThirtyDays : dataArrays.previousSevenDays

    const calculate = (data: any[]) => data.reduce((acc, row) => ({
      cost: acc.cost + (row.Cost || 0),
      revenue: acc.revenue + (row.ConvValue || 0),
      conversions: acc.conversions + (row.Conversions || 0),
      clicks: acc.clicks + (row.Clicks || 0)
    }), { cost: 0, revenue: 0, conversions: 0, clicks: 0 })

    const current = calculate(currentData)
    const previous = calculate(previousData)

    const getChange = (curr: number, prev: number) =>
      prev ? ((curr - prev) / prev * 100) : 0

    const profit = current.revenue * (1 - cogsPercentage / 100) - current.cost
    const previousProfit = previous.revenue * (1 - cogsPercentage / 100) - previous.cost

    const currentCPA = current.conversions > 0 ? current.cost / current.conversions : 0
    const previousCPA = previous.conversions > 0 ? previous.cost / previous.conversions : 0

    const currentConvRate = current.clicks > 0 ?
      ((current.conversions / current.clicks) * 100) : 0
    const previousConvRate = previous.clicks > 0 ?
      ((previous.conversions / previous.clicks) * 100) : 0

    const currentROAS = current.cost > 0 ? current.revenue / current.cost : 0
    const previousROAS = previous.cost > 0 ? previous.revenue / previous.cost : 0

    return [
      {
        label: "Total Cost",
        value: `${selectedCurrency}${Math.round(current.cost).toLocaleString()}`,
        change: getChange(current.cost, previous.cost),
        inverse: true
      },
      {
        label: "Total Revenue",
        value: `${selectedCurrency}${Math.round(current.revenue).toLocaleString()}`,
        change: getChange(current.revenue, previous.revenue)
      },
      {
        label: "Total Profit",
        value: `${selectedCurrency}${Math.round(profit).toLocaleString()}`,
        change: getChange(profit, previousProfit)
      },
      {
        label: "Avg CPA",
        value: currentCPA === 0 ? '-' : `${selectedCurrency}${currentCPA.toFixed(2)}`,
        change: getChange(currentCPA, previousCPA),
        inverse: true
      },
      {
        label: "Conv Rate",
        value: `${currentConvRate.toFixed(1)}%`,
        change: getChange(currentConvRate, previousConvRate)
      },
      {
        label: "ROAS",
        value: currentROAS === 0 ? '-' : `${currentROAS.toFixed(1)}x`,
        change: getChange(currentROAS, previousROAS)
      }
    ]
  }, [selectedPeriod, dataArrays, cogsPercentage, selectedCurrency])

  useEffect(() => {
    if (cogsAmount !== null) {
      setCogsPercentage(cogsAmount);
    }
  }, [cogsAmount]);


  // Early returns
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial' 
            ? "Loading your campaign data (this may take a minute or two)..." 
            : "Loading..."}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    );
  }

  if (!dataArrays.thirtyDayData?.length && !dataArrays.sevenDayData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No campaign data available. Please load data in Settings.
        </div>
      </div>
    );
  }


  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      {/* Performance Overview Card */}
      <Card className="w-full max-w-[1800px] mx-auto">
        <CardHeader>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <CardTitle>Overall Performance for All Campaigns</CardTitle>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="h-4 w-4 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Metrics compared to previous {selectedPeriod === '30d' ? '30' : '7'} day period</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-col items-end gap-1">
              <Tabs
                value={selectedPeriod}
                onValueChange={(value) => setSelectedPeriod(value as '30d' | '7d')}
                className="w-fit"
              >
                <TabsList className="bg-transparent gap-2">
                  <TabsTrigger
                    value="7d"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    7 Days
                  </TabsTrigger>
                  <TabsTrigger
                    value="30d"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    30 Days
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-8 gap-3">
            {performanceMetrics.map(({ label, value, change, inverse }) => (
              <div key={label} className="col-span-1">
                <p className="text-sm font-medium">{label}</p>
                <p className="text-xl font-bold">{value}</p>
                <div className="text-xs">
                  <span className={change > 0 === !inverse ? 'text-green-500' : 'text-red-500'}>
                    {change > 0 ? '↑' : '↓'} {Math.abs(Math.round(change))}%
                  </span>
                </div>
              </div>
            ))}
            <div className="col-span-2">
              <p className="text-sm font-medium mb-1">COGS %</p>
              <div className="flex items-center gap-2">
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  value={[cogsPercentage]}
                  onValueChange={async (value) => {
                    const newValue = Math.min(99, Math.max(1, value[0]));
                    setCogsPercentage(newValue);
                    await updateCogsAmount(newValue);
                  }}
                  className="flex-grow"
                />
                <span className="text-xl font-bold w-16 text-right">{cogsPercentage}%</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Filter Controls */}
      <div className="my-6">
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-3 flex-1">
            <span className="text-sm font-medium whitespace-nowrap">Include:</span>
            <input
              type="text"
              value={includeFilter}
              onChange={(e) => setIncludeFilter(e.target.value)}
              placeholder="Filter campaigns..."
              className="flex-1 h-9 px-3 rounded-md border border-input bg-background text-sm"
            />
          </div>
          <div className="flex items-center gap-3 flex-1">
            <span className="text-sm font-medium whitespace-nowrap">Exclude:</span>
            <input
              type="text"
              value={excludeFilter}
              onChange={(e) => setExcludeFilter(e.target.value)}
              placeholder="Exclude campaigns..."
              className="flex-1 h-9 px-3 rounded-md border border-input bg-background text-sm"
            />
          </div>
          <Select value={rowLimit.toString()} onValueChange={(value) => setRowLimit(Number(value))}>
            <SelectTrigger className="w-[130px]">
              <SelectValue placeholder="Show rows" />
            </SelectTrigger>
            <SelectContent>
              {[5, 10, 25, 50, 100].map(value => (
                <SelectItem key={value} value={value.toString()}>{value} rows</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Tabs and Budget Optimization */}
      <Tabs
        defaultValue="projections"
        onValueChange={setSelectedTab}
      >
        <div className="flex justify-between items-center">
          <TabsList className="h-12">
            <TabsTrigger value="projections">Budget Optimization</TabsTrigger>
            <TabsTrigger value="summary">Campaign Summary</TabsTrigger>
          </TabsList>

          {/* Optimization mode tabs */}
          {selectedTab === "projections" && (
            <Tabs
              value={optimizationMode}
              onValueChange={(value: string) => {
                if (['none', 'conservative', 'balanced', 'aggressive'].includes(value)) {
                  setOptimizationMode(value as OptimizationMode);
                }
              }}
              className="w-fit"
            >
              <TabsList className="bg-transparent gap-2">
                <TabsTrigger
                  value="none"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  None
                </TabsTrigger>
                <TabsTrigger
                  value="conservative"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  Conservative
                </TabsTrigger>
                <TabsTrigger
                  value="balanced"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  Balanced
                </TabsTrigger>
                <TabsTrigger
                  value="aggressive"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  Aggressive
                </TabsTrigger>
              </TabsList>
            </Tabs>
          )}
        </div>

        <TabsContent value="summary">
          <Card>
            <CardHeader>
              <CardTitle>Campaign Performance Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-foreground">
                  <thead>
                    <tr className="border-b border-border">
                      <th className="px-4 py-2 text-left w-[300px]">Campaign</th>
                      <th className="px-4 py-2 text-left w-[100px]">Cost</th>
                      <th className="px-4 py-2 text-left w-[100px]">Revenue</th>
                      <th className="px-4 py-2 text-left w-[100px]">Profit</th>
                      <th className="px-4 py-2 text-left w-[80px]">ROAS</th>
                      <th className="px-4 py-2 text-left w-[80px]">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="flex items-start gap-1">
                              <div className="flex flex-col">
                                <span>Impr.</span>
                                <span>Share</span>
                              </div>
                              <HelpCircle className="h-4 w-4" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Impression Share</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </th>
                      <th className="px-4 py-2 text-left w-[80px]">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="flex items-start gap-1">
                              <div className="flex flex-col">
                                <span>Lost IS</span>
                                <span>(Budget)</span>
                              </div>
                              <HelpCircle className="h-4 w-4" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Impression Share Lost Due to Budget</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </th>
                      <th className="px-4 py-2 text-left w-[80px]">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="flex items-start gap-1">
                              <div className="flex flex-col">
                                <span>Lost IS</span>
                                <span>(Rank)</span>
                              </div>
                              <HelpCircle className="h-4 w-4" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Impression Share Lost Due to Rank</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns
                      .filter(campaign => {
                        const name = campaign.Campaign?.toLowerCase() || '';
                        const include = includeFilter?.toLowerCase() || '';
                        const exclude = excludeFilter?.toLowerCase() || '';

                        if (include && !name.includes(include)) return false;
                        if (exclude && name.includes(exclude)) return false;
                        return true;
                      })
                      .slice(0, rowLimit)
                      .map(campaign => {
                        const revenue = campaign.ConvValue;
                        const cost = campaign.Cost;
                        const grossRevenue = revenue * (1 - cogsPercentage / 100);
                        const profit = grossRevenue - cost;
                        const roas = cost > 0 ? revenue / cost : 0;
                        const impressionShare = campaign.ImprShare * 100;
                        const lostToBudget = campaign.LostToBudget * 100;
                        const lostToRank = campaign.LostToRank * 100;
                        const hasImpressionShareMetrics = campaign.ImprShare !== 0 || campaign.LostToBudget !== 0 || campaign.LostToRank !== 0;

                        return (
                          <tr key={campaign.Campaign} className="border-b border-border hover:bg-muted/50">
                            <td className="px-4 py-2">{campaign.Campaign}</td>
                            <td className="px-4 py-2">{selectedCurrency}{Math.round(cost).toLocaleString()}</td>
                            <td className="px-4 py-2">{selectedCurrency}{Math.round(revenue).toLocaleString()}</td>
                            <td className="px-4 py-2">
                              <span className={profit < 0 ? 'text-red-500' : 'text-green-500'}>
                                {selectedCurrency}{Math.round(profit).toLocaleString()}
                              </span>
                            </td>
                            <td className="px-4 py-2">{roas.toFixed(1)}x</td>
                            <td className="px-4 py-2">{hasImpressionShareMetrics ? `${impressionShare.toFixed(1)}%` : '-'}</td>
                            <td className="px-4 py-2">{hasImpressionShareMetrics ? `${lostToBudget.toFixed(1)}%` : '-'}</td>
                            <td className="px-4 py-2">{hasImpressionShareMetrics ? `${lostToRank.toFixed(1)}%` : '-'}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="projections">
          <BudgetOptimization
            campaigns={campaigns}
            cogsPercentage={cogsPercentage}
            includeFilter={includeFilter}
            excludeFilter={excludeFilter}
            rowLimit={rowLimit}
            optimizationMode={optimizationMode}
          />
        </TabsContent>
      </Tabs>
    </div>
  )
}