// src/lib/profit-utils.ts

export interface Campaign {
  Campaign: string;
  Cost: number;
  ConvValue: number;
  Clicks: number;
  Conversions: number;
  ImprShare: number;
  LostToBudget: number;
  LostToRank: number;
  Impressions: number;
}

export interface OptimalZone {
  start: number;
  end: number;
  current: number;
  maxProfit: number;
}

export interface ProfitDataPoint {
  cost: number;
  sales: number;
  convValue: number;
  roas: number;
  profit: number;
  marginalROAS: number;
  aov: number;
}

export interface ProjectionConfig {
  currentCost: number;
  currentRevenue: number;
  conversions: number;
  cogsPercentage: number;
  impressionShare: number;
  scalingFactor?: number; // Default 0.4
}

export interface ProjectedMetrics {
  projectedCost: number;
  projectedClicks: number;
  projectedConversions: number;
  projectedRevenue: number;
  projectedProfit: number;
  projectedImpressions: number;
  profitChange: number;
  percentChange: number;
  projectedIS: number;
}

export interface Recommendation {
  recommendation: string;
  recommendationDetail: string;
  adjustmentPercent: number;
}

export function calculateProfitProjections(config: ProjectionConfig): ProfitDataPoint[] {
  const {
    currentCost,
    currentRevenue,
    conversions,
    cogsPercentage,
    impressionShare,
    scalingFactor = 0.4
  } = config;

  const data: ProfitDataPoint[] = [];
  const numPoints = 100;
  const maxCost = currentCost * 3;
  const minCost = currentCost * 0.1;
  const step = (maxCost - minCost) / (numPoints - 1);

  // Calculate base metrics
  const baseAOV = conversions > 0 ? currentRevenue / conversions : 0;

  for (let i = 0; i < numPoints; i++) {
    const cost = minCost + i * step;

    // Scale sales based on cost using diminishing returns
    const scaleFactor = Math.pow(cost / currentCost, scalingFactor);
    const sales = conversions * scaleFactor;
    const convValue = sales * baseAOV;
    const roas = cost > 0 ? convValue / cost : 0;

    // Calculate profit
    const grossProfit = convValue * (1 - cogsPercentage / 100);
    const profit = grossProfit - cost;

    // Calculate marginal ROAS
    let marginalROAS = roas;
    if (i > 0) {
      const prevData = data[i - 1];
      const additionalCost = cost - prevData.cost;
      const additionalConvValue = convValue - prevData.convValue;
      marginalROAS = additionalCost > 0 ? additionalConvValue / additionalCost : 0;
    }

    data.push({
      cost,
      sales,
      convValue,
      roas,
      profit,
      marginalROAS,
      aov: baseAOV
    });
  }

  return data;
}

export function findOptimalZone(profitData: ProfitDataPoint[], currentCost: number): OptimalZone {
  if (!profitData.length) {
    return { start: 0, end: 0, current: currentCost, maxProfit: 0 };
  }

  const maxProfitPoint = profitData.reduce((max, point) =>
    point.profit > max.profit ? point : max, profitData[0]
  );

  // If the campaign is unprofitable at all points, return zero range
  if (maxProfitPoint.profit <= 0) {
    return {
      start: 0,
      end: 0,
      current: currentCost,
      maxProfit: maxProfitPoint.profit
    };
  }

  // Only consider profitable points for the optimal range
  const profitablePoints = profitData.filter(point => point.profit > 0);

  // If there are no profitable points, return zero range
  if (profitablePoints.length === 0) {
    return {
      start: 0,
      end: 0,
      current: currentCost,
      maxProfit: maxProfitPoint.profit
    };
  }

  // Find range within 95% of max profit, but only among profitable points
  const profitThreshold = maxProfitPoint.profit * 0.95;
  const profitRange = profitablePoints.filter(point => point.profit >= profitThreshold);

  return {
    start: Math.min(...profitRange.map(p => p.cost)),
    end: Math.max(...profitRange.map(p => p.cost)),
    current: currentCost,
    maxProfit: maxProfitPoint.profit
  };
}

export function getRecommendation(campaign: Campaign, profitData: ProfitDataPoint[], optimalZone: OptimalZone): Recommendation {
  const isHighIS = campaign.ImprShare >= 0.9;
  const tolerance = campaign.Cost * 0.001;
  const currentProfit = profitData.find(p => Math.abs(p.cost - campaign.Cost) < tolerance)?.profit ||
    profitData.reduce((nearest, point) =>
      Math.abs(point.cost - campaign.Cost) < Math.abs(nearest.cost - campaign.Cost) ? point : nearest
    ).profit;

  // Check if campaign is profitable at any point
  const maxProfitPoint = profitData.reduce((max, point) =>
    point.profit > max.profit ? point : max, profitData[0]
  );

  if (maxProfitPoint.profit <= 0) {
    const isPauseCandidate = currentProfit <= -campaign.Cost * 0.5;
    
    return {
      recommendation: isPauseCandidate ? 'Pause Campaign' : 'Decrease Cost',
      recommendationDetail: isPauseCandidate ? 
        'Consider pausing - losing over 50% of spend' : 
        'Not profitable at any spend level',
      adjustmentPercent: -100
    };
  }

  if (isHighIS) {
    return {
      recommendation: 'No Change',
      recommendationDetail: `At ${(campaign.ImprShare * 100).toFixed(0)}% impression share`,
      adjustmentPercent: 0
    };
  }

  const percentFromOptimalStart = optimalZone.start > 0 ?
    ((campaign.Cost - optimalZone.start) / optimalZone.start) * 100 : 0;
  const percentFromOptimalEnd = optimalZone.end > 0 ?
    ((campaign.Cost - optimalZone.end) / optimalZone.end) * 100 : 0;

  if (optimalZone.start === 0 && optimalZone.end === 0) {
    return {
      recommendation: 'Decrease Cost',
      recommendationDetail: 'Not profitable at any spend level',
      adjustmentPercent: -100
    };
  }

  // If INSIDE optimal range
  if (campaign.Cost >= optimalZone.start && campaign.Cost <= optimalZone.end) {
    return {
      recommendation: 'No Change',
      recommendationDetail: 'Within optimal cost range',
      adjustmentPercent: 0
    };
  }

  // If below optimal start
  if (campaign.Cost < optimalZone.start) {
    const detail = campaign.LostToBudget > 0.05
      ? `${Math.round(campaign.LostToBudget * 100)}% IS lost to budget. ${Math.abs(Math.round(percentFromOptimalStart))}% below optimal`
      : `${Math.abs(Math.round(percentFromOptimalStart))}% below optimal range ($${Math.round(optimalZone.start).toLocaleString()})`;

    return {
      recommendation: 'Increase Cost',
      recommendationDetail: detail,
      adjustmentPercent: Math.min(100, ((optimalZone.start - campaign.Cost) / campaign.Cost) * 100)
    };
  }

  // If above optimal end
  if (campaign.Cost > optimalZone.end) {
    return {
      recommendation: 'Decrease Cost',
      recommendationDetail: `${Math.round(percentFromOptimalEnd)}% above optimal range ($${Math.round(optimalZone.end).toLocaleString()})`,
      adjustmentPercent: Math.max(-100, ((optimalZone.end - campaign.Cost) / campaign.Cost) * 100)
    };
  }

  // This should never happen given the conditions above, but TypeScript wants it
  return {
    recommendation: 'No Change',
    recommendationDetail: 'Within optimal cost range',
    adjustmentPercent: 0
  };
}

export function projectMetrics(campaign: Campaign, adjustmentPercent: number, cogsPercentage: number) {
  // If no adjustment, return current values
  if (adjustmentPercent === 0) {
    const currentProfit = campaign.ConvValue * (1 - cogsPercentage / 100) - campaign.Cost;
    return {
      projectedCost: campaign.Cost,
      projectedClicks: campaign.Clicks,
      projectedConversions: campaign.Conversions,
      projectedRevenue: campaign.ConvValue,
      projectedProfit: currentProfit,
      projectedImpressions: campaign.Impressions,
      profitChange: 0,
      percentChange: 0,
      projectedIS: campaign.ImprShare
    };
  }

  // Calculate projected cost based on adjustment percentage
  const projectedCost = campaign.Cost * (1 + adjustmentPercent / 100);

  // Calculate projected metrics using the same ratios as current performance
  const costRatio = projectedCost / campaign.Cost;

  // Project clicks and conversions based on cost ratio
  const projectedClicks = campaign.Clicks * costRatio;
  const projectedConversions = campaign.Conversions * costRatio;

  // Project revenue using the same conversion value per conversion
  const conversionValue = campaign.Conversions > 0 ? campaign.ConvValue / campaign.Conversions : 0;
  const projectedRevenue = projectedConversions * conversionValue;

  // Calculate profits
  const currentProfit = campaign.ConvValue * (1 - cogsPercentage / 100) - campaign.Cost;
  const projectedProfit = projectedRevenue * (1 - cogsPercentage / 100) - projectedCost;
  const profitChange = projectedProfit - currentProfit;

  // Calculate percentage change in cost
  const percentChange = ((projectedCost - campaign.Cost) / campaign.Cost) * 100;

  // Project impression share
  const projectedIS = Math.min(1, campaign.ImprShare * (1 + adjustmentPercent / 200));

  // Project impressions
  const projectedImpressions = campaign.Impressions * costRatio;

  return {
    projectedCost,
    projectedClicks,
    projectedConversions,
    projectedRevenue,
    projectedProfit,
    projectedImpressions,
    profitChange,
    percentChange,
    projectedIS
  };
}