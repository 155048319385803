// src/lib/script-levels.ts
import { 
LEVEL_1_CODE,
LEVEL_2_CODE,
LEVEL_3_CODE,
LEVEL_4_CODE,
LEVEL_5_CODE,
LEVEL_6_CODE,
LEVEL_7_CODE,
LEVEL_8_CODE,
LEVEL_9_CODE,
LEVEL_10_CODE
} from './script-code';

export const levels = [
{
id: 1,
title: "Level 1: Let's Start Simple – Copy & Paste",
description: "Begin by running a basic script that logs a simple message. This will help you see how Google Ads Scripts work.",
preCodeTasks: [
"Open Google Ads & click on Tools in the left menu.",
"Click on Bulk Actions, then Scripts.",
"Click the big blue '+' button & then 'New Script'.",
"Click the button below to copy this 3-line script to the clipboard.",
],
postCodeTasks: [
"Name your script 'Level 1'.",

"Delete any code you see & paste in the code from the clipboard.",
"Click 'Preview' to get the script running.",
"You should see an 'Authorize' button - click it (you may need to scroll down to see it).",
"Click your Google login & then 'Allow'. If it says 'App Blocked' talk to your Google admin.",
"(Preview essentially does the same thing as clicking 'Run'. Except you can see the logs as it runs, which can be useful.)",
"Check the output of your script by clicking 'Logs' just under your code & above the 'Preview' link."
],
code: LEVEL_1_CODE,
expectedOutput: "Hello, Google Ads Scripts!",
images: "public/images/logs.png"
},
{
id: 2,
title: "Level 2: Find Your Account Info",
description: "Learn how to pull basic details about your account, like its name and ID. A quick way to see what's available.",
preCodeTasks: [
"Click the button below to copy this script to the clipboard.",
"Follow the steps from Level 1 to create a new script, or just edit your existing script in Google Ads (just delete the old code & paste this in)."
],
postCodeTasks: [
"Click 'Preview' to get the script running.",
"Check the console output by clicking 'Logs' just under your code."
],
code: LEVEL_2_CODE,
expectedOutput: [
"Account Name: [Your Account Name]",
"Account ID: [Your Account ID]"
],
},
{
id: 3,
title: "Level 3: Check Your Key Stats",
description: "Discover how to pull numbers like impressions, clicks, and CTR. These are the building blocks for analyzing performance.",
preCodeTasks: [
"You know what to do...Click the button below to copy this script to the clipboard.",
"Paste your script into Google Ads (delete the old code first)."
],
postCodeTasks: [
"This time let's click 'Run' instead of 'Preview'.",
"A lightbox will appear, click 'Run without Preview'.",
"You'll end up on the 'Script History' page. Click 'Logs' to see the output of your script.",
"To get back to your script, click 'Scripts' at the top.",
"This screen is where you can schedule your script to run on a regular basis, look for the 'Frequency' column.",
"To get back to editing your script, just click on the script name."
],
code: LEVEL_3_CODE,
expectedOutput: [
"Impressions: [Number]",
"Clicks: [Number]",
"CTR: [Percentage]"
],
},
{
id: 4,
title: "Level 4: Choose Your Date Range",
description: "Change the date range in your script to see data for different time periods, like yesterday or the last 7 days. While we're at it, let's add some more metrics!",
preCodeTasks: [
"Copy the code.",
"Paste it into Google Ads."
],
postCodeTasks: [
"Click 'Preview'.",
],
code: LEVEL_4_CODE,
expectedOutput: [
"Impressions: [Number]",
"Clicks: [Number]",
"CTR: [Percentage]",
"Cost: [Currency]",
"Conv: [Number]",
],
},
{
id: 5,
title: "Level 5: Save Data to Google Sheets",
description: `Logging metrics on the screen can be useful, but it's even better to save them to a Google Sheet.
So let's do that! This time a Google Sheet will be created for you - remember to check the logs to get the link.`,
preCodeTasks: [
"Copy the code.",
"Paste it into Google Ads."
],
postCodeTasks: [
"Click 'Preview'.",
"This time you'll need to Authorize the script. Click the Authorize link, then click your Google login & then 'Allow'.",
"Click 'Preview' again to run the script.",
"Find the URL of your sheet by looking at the logs.",
"Open your sheet in a new tab & explore the data."
],
code: LEVEL_5_CODE,
expectedOutput: [
"Sheet URL: [Google Sheet Link]",
],
images: "public/images/authorize.png"
},
{
id: 6,
title: "Level 6: Organize Data into Tabs",
description: `Now that you're getting the hang of this, we're going to try a new way of getting data. It's faster but looks a bit scarier.
Let's create 3 tabs with data for campaigns, ad groups, and keywords. This will make it easier to read and report on.\n
After all, the reason to pull this data out is to create reports & charts and gain new insights.
This code is going to be a bit longer, so we'll move the 'copy button' above the code from now on :)\n
It should take about a minute or two to run.
Want to learn more? Try pasting this code into chatGPT or Claude.ai & ask it to explain what each part does.
NOTE: This code only looks for SEARCH campaigns. But try changing it (with AI) to get data for other campaign types.`,
preCodeTasks: [
"Copy the code.",
"Paste it into Google Ads."
],
postCodeTasks: [
"Click 'Preview'.",
"Once the code is finished, copy the URL into a tab & explore the sheet."
],
code: LEVEL_6_CODE,
expectedOutput: [
"Report created! URL: [Google Sheet Link]",
],
},
{
id: 7,
title: "Level 7: Making Your Sheet More Useful",
description: `Now that you've created your first report, you might notice it contains a lot of rows with zeros - campaigns or keywords with no activity.
(Assuming you had some Search campaigns & keywords in your account).
Let's clean up our data by filtering out these inactive items. This will make your report:
• Easier to read
• Faster to generate
• More focused on what matters
We'll also format the numbers to make them more readable.`,
preCodeTasks: [
"Copy the code below.",
"Create a new script in Google Ads (or edit your existing one).",
"With a bit of help from AI, you could try adjusting the filters in the code to match your needs."
],
postCodeTasks: [
"Click 'Preview' to run the script.",
"Compare this sheet to your previous one - notice how much cleaner (& faster) it is!",
"Try adjusting the filters (like adding a rule to only get entities with 100 impressions or more) to see how it affects your data."
],
code: LEVEL_7_CODE,
expectedOutput: [
"Filtered report created! URL: [Google Sheet Link]"
],
},
{
id: 8,
title: "Level 8: Using the same sheet every time",
description: `So far we've been creating a new sheet every time the script runs. While this is useful for testing, in practice you'll want to update the same sheet repeatedly.
Let's modify our script to use the same sheet everytime. This way you can: add your own formatting and charts, track changes over time, share the sheet with your team & schedule the script to auto-update your sheet

⚠️ Common Mistakes to Avoid:
1. Don't remove the '/edit' or similar from the end of the URL
2. Don't try to "clean up" the URL - paste it exactly as it appears
3. Don't remove any characters from the sheet ID
4. Make sure you're using the full URL, not just the sheet ID`,
preCodeTasks: [
"Copy the complete code",
"Paste it into Google Ads (or edit your existing script)",
],
postCodeTasks: [
"IMPORTANT - Use the same Google account for the sheet that you use for Google Ads",
"Open a new browser tab",
"Type 'sheet.new' and press Enter to create a new, blank Google Sheet",
"Copy the entire URL from your browser's address bar",
"In the code in Google Ads, find row 3.",
"Enter the url of your new sheet into the SHEET_URL variable (between the single quotes)",
"Click 'Preview' to run the script",
"Check that data appears in your sheet",
"Try running it again - notice it updates the same sheet instead of creating a new one",
],
code: LEVEL_8_CODE,
expectedOutput: [
"Data updated in sheet: [Your Google Sheet URL]"
],
images: "images/sheet-url.png"
},
{
id: 9,
title: "Level 9: Let's automate some email alerts",
description: "Set up automated email alerts when enabled campaigns get zero impressions. \nPerfect for catching issues early, like disapproved ads or incorrect targeting.",
preCodeTasks: [
"Copy the code below & paste it into Google Ads.",
],
postCodeTasks: [
"Add your email address at the top of the script  - line 1 this time.",
"Add your email address in the YOUR_EMAIL variable (between the single quotes).",
"Click 'Preview' to test the script.",
"The script will only email you if there's a problem - no email means everything's running fine!",
"If you want to test that it works, try setting the condition to 'Impressions > 0' and run the script again. (ie change line 6 to:     .withCondition('Impressions > 0')  )",
"Note the email might go to spam!"
],
code: LEVEL_9_CODE,
expectedOutput: [
"Alert email sent to [YOUR_EMAIL_ADDRESS]"
],
},
{
id: 10,
title: "Level 10: Control Budgets with BudgetMax",
description: "CONGRATULATIONS! You've completed all the levels. \nNow it's time to use the script in this app to get insights about your campaign budgets. \n\nLogin to this app (if you haven't already) & go through the setup process.",
preCodeTasks: [
"No code to copy this time - just login to the app & go through the setup process."
],
postCodeTasks: [
"Go celebrate! You're a Google Ads Scripts hero!"
],
code: LEVEL_10_CODE,
expectedOutput: [
"Nothing here... gone to the pub!"
],
},
];
