// src/lib/constants.ts

// Get App URL from localStorage
export const getWebAppUrl = (): string | null => {
  return localStorage.getItem(STORAGE_KEYS.WEB_APP_URL);
};

// Sheet tabs configuration
export const SHEET_TABS = {
  DAILY: 'daily',
  THIRTY_DAYS: 'thirty_days',
  P_THIRTY_DAYS: 'p_thirty_days',
  SEVEN_DAYS: 'seven_days',
  P_SEVEN_DAYS: 'p_seven_days',
  HOURLY: 'hourly',
  HOURLY_YEST: 'hourly_yest',
  SETTINGS: 'settings',
  PRODUCTS: 'products',
  SEARCH_TERMS: 'search_terms',
  PMAX_PERF: 'pmax_perf'
} as const;

export const STORAGE_KEYS = {
  CAMPAIGN_DATA: 'campaignData',
  ROW_LIMIT: 'campaign_row_limit',
  WEB_APP_URL: 'webAppUrl',
  SETUP_STATE: 'setupState',
  SETUP_DATA: 'setupData',
  USE_SAMPLE_DATA: 'useSampleData',
  USER_CAMPAIGN_DATA: 'userCampaignData',
  IS_CONFIGURED: 'isConfigured',
  GAME_LEVEL: 'currentGameLevel',
} as const;

// Cache duration in milliseconds
export const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

// Data sources configuration
export const DATA_SOURCES = {
  [SHEET_TABS.DAILY]: { title: 'Daily Data', description: 'Daily campaign metrics.' },
  [SHEET_TABS.THIRTY_DAYS]: { title: '30 Day Data', description: '30-day aggregated metrics' },
  [SHEET_TABS.P_THIRTY_DAYS]: { title: 'Previous 30 Days', description: '31-60 day comparison metrics' },
  [SHEET_TABS.SEVEN_DAYS]: { title: '7 Day Data', description: '7-day aggregated metrics' },
  [SHEET_TABS.P_SEVEN_DAYS]: { title: 'Previous 7 Days', description: '8-14 day comparison metrics' },
  [SHEET_TABS.HOURLY]: { title: "Today's Hourly", description: 'Hourly metrics for today' },
  [SHEET_TABS.HOURLY_YEST]: { title: "Yesterday's Hourly", description: 'Hourly metrics for yesterday' },
  [SHEET_TABS.SETTINGS]: { title: 'Campaign Settings', description: 'Campaign settings and configuration' },
  [SHEET_TABS.PMAX_PERF]: { title: 'PMax Performance', description: 'Performance Max campaign performance' },
  [SHEET_TABS.PRODUCTS]: { title: 'Product Data', description: 'Product performance metrics' },
  [SHEET_TABS.SEARCH_TERMS]: { title: 'Search Terms', description: 'Search query performance data' }
} as const;


export const CURRENCIES = [
  { symbol: '$', label: 'Dollar' },
  { symbol: '€', label: 'Euro' },
  { symbol: '£', label: 'Pound' },
  { symbol: '¥', label: 'Yen' },
  { symbol: 'zł', label: 'Zloty' },
  { symbol: '₹', label: 'Rupee' },
  { symbol: '₽', label: 'Ruble' },
  { symbol: '₩', label: 'Won' },
  { symbol: '₣', label: 'Franc' },
  { symbol: '₫', label: 'Dong' },
  { symbol: '₱', label: 'Peso' },
  { symbol: 'R$', label: 'Real' }
] as const;

export const METRIC_FORMATS = {
  CURRENCY: (v: number) => `$${Math.round(v).toLocaleString()}`,
  CURRENCY_2_DEC: (v: number) => `$${v.toFixed(2)}`,
  NUMBER: (v: number) => Math.round(v).toLocaleString(),
  PERCENT: (v: number) => `${Math.round(v)}%`,
  PERCENT_1_DEC: (v: number) => `${v.toFixed(1)}%`,
  ROAS: (v: number) => `${v.toFixed(1)}x`,
} as const;


export const PMAX_METRICS = [
  {
      key: 'cost',
      label: 'Cost',
      format: (v: number) => `$${Math.round(v).toLocaleString()}`
  },
  {
      key: 'cost_share',
      label: 'Cost %',
      format: (v: number) => `${v.toFixed(1)}%`
  },
  {
      key: 'conversions',
      label: 'Conv',
      format: (v: number) => Math.round(v).toLocaleString()
  },
  {
      key: 'conv_share',
      label: 'Conv %',
      format: (v: number) => `${v.toFixed(1)}%`
  },
  {
      key: 'value',
      label: 'Value',
      format: (v: number) => `$${Math.round(v).toLocaleString()}`
  },
  {
      key: 'value_share',
      label: 'Value %',
      format: (v: number) => `${v.toFixed(1)}%`
  }
] as const;

export const PMAX_CHANNELS = ['Shopping', 'Display', 'Video', 'Search'] as const;

export type PMaxChannel = typeof PMAX_CHANNELS[number];
export type PMaxMetricKey = typeof PMAX_METRICS[number]['key'];
export type TabKey = keyof typeof DATA_SOURCES;
export type FetchStatus = 'loading' | 'success' | 'error';

// Setup state management
interface SetupState {
  isComplete: boolean;
  sheetUrl?: string;
  webAppUrl?: string;
  completedAt?: string;
  currentStep?: number;
}

// Cache the configuration status
let configurationStatus: boolean | null = null;
let lastCheck = 0;
const CHECK_INTERVAL = 5000; // 5 seconds

export const isConfigured = () => {
  const now = Date.now();
  
  // Only check if we haven't checked before or if enough time has passed
  if (configurationStatus === null || now - lastCheck > CHECK_INTERVAL) {
    const setupData = localStorage.getItem(STORAGE_KEYS.SETUP_DATA);
    configurationStatus = !!setupData;
    lastCheck = now;
  }

  return configurationStatus;
};

// Add a function to reset the cache when needed
export const resetConfigurationStatus = () => {
  configurationStatus = null;
};

export const getSetupState = (): SetupState | null => {
  try {
    const state = localStorage.getItem(STORAGE_KEYS.SETUP_STATE);
    return state ? JSON.parse(state) : null;
  } catch (error) {
    console.error('Error parsing setup state:', error);
    return null;
  }
};

export const saveSetupState = (state: SetupState): void => {
  try {
    localStorage.setItem(STORAGE_KEYS.SETUP_STATE, JSON.stringify(state));
  } catch (error) {
    console.error('Error saving setup state:', error);
  }
};

export const completeSetup = (sheetUrl: string, webAppUrl: string): SetupState => {
  const setupData: SetupState = {
    isComplete: true,
    sheetUrl,
    webAppUrl,
    completedAt: new Date().toISOString()
  };
  
  try {
    // Save both the setup data and the web app URL
    localStorage.setItem(STORAGE_KEYS.SETUP_DATA, JSON.stringify(setupData));
    localStorage.setItem(STORAGE_KEYS.WEB_APP_URL, webAppUrl);
    return setupData;
  } catch (error) {
    console.error('Error completing setup:', error);
    throw error;
  }
};