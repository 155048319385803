// src/main.tsx
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ClerkProvider } from "@clerk/clerk-react"
import { ThemeProvider } from "@/components/theme-provider"
import { CampaignDataProvider } from "@/contexts/campaign-data"
import { router } from './Router'
import './index.css'
import { RouterProvider } from 'react-router-dom'

// Environment detection
const isDev = import.meta.env.MODE === 'development' || 
             window.location.hostname.includes('replit.dev');

const CLERK_KEY = isDev 
  ? import.meta.env.VITE_CLERK_DEV_KEY 
  : import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!CLERK_KEY) {
  throw new Error("Missing Clerk Key")
}

const root = document.getElementById('root')

if (!root) {
  throw new Error('Root element not found!')
} 

const reactRoot = ReactDOM.createRoot(root)

reactRoot.render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={CLERK_KEY}
      appearance={{
        elements: {
          rootBox: "mx-auto w-full max-w-[440px]",
          card: "shadow-none"
        }
      }}
      {...(isDev && {
        allowedRedirectOrigins: [
          'http://localhost:3000',
          'http://localhost:5173',
          window.location.origin
        ]
      })}
    >
      <ThemeProvider defaultTheme="light">
        <CampaignDataProvider>
          <RouterProvider router={router} />
        </CampaignDataProvider>
      </ThemeProvider>
    </ClerkProvider>
  </React.StrictMode>
)