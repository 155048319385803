import { useState } from "react"
import { Card, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"

const GOOGLE_SCRIPT_URL = "https://script.google.com/macros/s/AKfycbxFKlWOSg-kVdejxJ1xEMXa1ftMd61rKrEytJahia-ziT1K3TaVmUdni5Tpmk024-OlSA/exec"

interface FeedbackFormProps {
  onSubmitSuccess?: () => void
}

export function FeedbackForm({ onSubmitSuccess }: FeedbackFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsSubmitting(true)

    const formData = new FormData(e.currentTarget)
    const data = {
      email: formData.get("email"),
      feedback: formData.get("feedback"),
      timestamp: new Date().toISOString()
    }

    try {
      const response = await fetch(GOOGLE_SCRIPT_URL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      })

      setSubmitted(true)
      onSubmitSuccess?.()
      e.currentTarget.reset()
    } catch (error) {
      console.error("Failed to submit feedback:", error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Card>
      <CardContent className="p-6">
        {submitted ? (
          <div className="text-center py-8">
            <h3 className="text-xl font-semibold mb-2">Thank you for your feedback!</h3>
            <p className="text-muted-foreground">We appreciate your input and will review it carefully.</p>
            <Button 
              className="mt-4"
              onClick={() => setSubmitted(false)}
            >
              Submit Another Response
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Input
                name="email"
                type="email"
                placeholder="Your email (optional)"
              />
            </div>
            <div>
              <Textarea
                name="feedback"
                placeholder="Share your thoughts, suggestions, or report issues..."
                required
                className="min-h-[150px]"
              />
            </div>
            <Button 
              type="submit" 
              className="w-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Feedback"}
            </Button>
          </form>
        )}
      </CardContent>
    </Card>
  )
}