// src/components/navigation/navbar.tsx
import { Link, useLocation } from "react-router-dom";
import { Logo } from "@/components/logo";
import { getUpdatedNav } from "@/config/menu";
import { ThemeToggle } from "@/components/theme-toggle";
import { HelpCircle, Settings } from "lucide-react";
import { cn } from "@/lib/utils";
import { SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";
import { useCampaignData } from "@/contexts/campaign-data";

export function Navbar() {
  const location = useLocation();
  const { currentGameLevel } = useCampaignData();
  const mainNav = getUpdatedNav(currentGameLevel);

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 flex h-14 items-center justify-between">
        <div className="flex items-center space-x-6">
          {/* Logo with hover effect */}
          <Link 
            to="/" 
            className="mr-6 flex items-center space-x-2 transition-all duration-200 hover:opacity-80"
          >
            <Logo />
            <span className="hidden font-bold sm:inline-block">
              Budget Maximizer
            </span>
          </Link>
          
          {/* Navigation items with enhanced hover effects */}
          <nav className="flex items-center space-x-6 text-sm font-medium">
            {mainNav
              .filter((item) => !item.hidden)
              .map((item) => (
                <Link
                  key={item.title}
                  to={item.href || ''}
                  className={cn(
                    "relative py-1 transition-all duration-200",
                    "hover:text-foreground hover:translate-y-[-1px]",
                    "active:translate-y-[1px]",
                    location.pathname === item.href 
                      ? [
                          "text-foreground",
                          "after:absolute after:bottom-0 after:left-0 after:right-0",
                          "after:h-[2px] after:bg-foreground",
                          "after:transition-all after:duration-200"
                        ].join(" ")
                      : "text-foreground/60"
                  )}
                >
                  {item.title}
                </Link>
              ))}
          </nav>
        </div>
        
        <div className="flex items-center space-x-4">
          <ThemeToggle />
          
          {/* Settings icon with enhanced hover effect */}
          <Link 
            to="/settings"
            className={cn(
              "p-2 rounded-md transition-all duration-200",
              "hover:translate-y-[-1px] active:translate-y-[1px]",
              location.pathname === '/settings'
                ? "bg-accent text-accent-foreground"
                : "hover:bg-accent/50"
            )}
            title="Settings"
          >
            <Settings className="h-5 w-5" />
          </Link>

          {/* Help & Feedback icon with enhanced hover effect */}
          <Link 
            to="/help"
            className={cn(
              "p-2 rounded-md transition-all duration-200",
              "hover:translate-y-[-1px] active:translate-y-[1px]",
              location.pathname === '/help'
                ? "bg-accent text-accent-foreground"
                : "hover:bg-accent/50"
            )}
            title="Help & Feedback"
          >
            <HelpCircle className="h-5 w-5" />
          </Link>

          {/* Auth buttons */}
          <SignedOut>
            <Button variant="outline" asChild>
              <Link to="/sign-in">Sign In</Link>
            </Button>
          </SignedOut>
          <SignedIn>
            <UserButton 
              afterSignOutUrl="/"
              appearance={{
                elements: {
                  avatarBox: "w-8 h-8"
                }
              }}
            />
          </SignedIn>
        </div>
      </div>
    </header>
  );
}