// src/hooks/use-currency.ts
import { useState, useEffect } from 'react';

export const useCurrency = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>(() => {
    try {
      return localStorage.getItem('currency') || '$';
    } catch (error) {
      console.error('Error reading currency from localStorage:', error);
      return '$';
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem('currency', selectedCurrency);
    } catch (error) {
      console.error('Error saving currency to localStorage:', error);
    }
  }, [selectedCurrency]);

  return {
    selectedCurrency,
    setSelectedCurrency,
  };
};